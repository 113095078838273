import { useEffect, useState } from "react";
import { Link, Outlet, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Stack from "react-bootstrap/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCompressArrowsAlt, faExpandArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import { inboxWidth, formatDate } from "../utils";
import { useAuth } from "../AuthContext";
import CollapseList from "./CollapseList";
import { InboxItem } from "./Inbox";
import { OutboxItem } from "./Outbox";
import { DraftItem } from "./Drafts";
import { TemplateItem } from "./Templates";
import { ArchiveItem } from "./Archives";

export default function Search() {
  const { docid } = useParams();
  const [searchParams] = useSearchParams();
  const [user] = useAuth();
  const { docs, setDocs, err } = useOutletContext();
  const [thisDocs, setThisDocs] = useState([]);
  const [boxWidth, setBoxWidth] = useState(docid ? 2 : 1);

  useEffect(() => {
    if (boxWidth) setBoxWidth(docid ? 2 : 1)
  }, [docid]);


  useEffect(() => {
    let searchword = searchParams.get("name")?.toLowerCase();
    setThisDocs(docs.filter(doc => doc.name.toLowerCase().includes(searchword)
      || doc.owner.firstname.toLowerCase().includes(searchword)));
  }, [docs, searchParams.get("name")]);

  return <>
    <ListGroup variant="flush" className="bg-white border-end py-2 overflow-scroll"
      style={{width: inboxWidth[boxWidth], zIndex: "2"}}>
      <ListGroup.Item className="d-flex bg-light">
        <b>Search results</b>
        <Button variant="light" size="sm" className="ms-auto"
          onClick={() => setBoxWidth(boxWidth ? 0 : docid ? 2 : 1)}>
          <FontAwesomeIcon icon={boxWidth ? faCompressArrowsAlt : faExpandArrowsAlt} />
        </Button>
      </ListGroup.Item>
      {err?.message && <ListGroup.Item>{err?.message}</ListGroup.Item>}
      <CollapseList title="Received">
        {thisDocs.filter(doc => (doc.state === 2 && doc.signers.find(signer => signer.userid === user.userid)?.state === 1)
          || ([2, 3].includes(doc.state) && doc.signers.find(signer => signer.userid === user.userid)?.state === 2)
        ).map(doc => <InboxItem key={doc.docid} doc={doc} complete={false} summary={boxWidth === 0} />)}
      </CollapseList>
      <CollapseList title="Sent">
        {thisDocs.filter(doc => doc.ownerid === user.userid && doc.state === 2).map(doc => 
          <OutboxItem key={doc.docid} doc={doc} complete={false} summary={boxWidth === 0} />)}
      </CollapseList>
      <CollapseList title="Drafts">
        {thisDocs.filter(doc => doc.ownerid === user.userid && doc.state === 0).map(doc => 
          <DraftItem key={doc.docid} doc={doc} summary={boxWidth === 0} />)}
      </CollapseList>
      <CollapseList title="Templates">
        {thisDocs.filter(doc => doc.ownerid === user.userid && doc.state === 1).map(doc => 
          <TemplateItem key={doc.docid} doc={doc} summary={boxWidth === 0} />)}
      </CollapseList>
      <CollapseList title="Archives">
        {thisDocs.filter(doc => doc.state === 3 && doc.signers.find(signer => signer.userid === user.userid)?.state === 3).map(doc =>
          <ArchiveItem key={doc.docid} doc={doc} summary={boxWidth === 0} />)}
      </CollapseList>
      {/*thisDocs.map(doc => <ListGroup.Item key={doc.docid} as={Link} to={`${doc.docid}?name=${searchParams.get("name")}`}
        className={`${doc.signers.find(signer => signer.userid === user.userid)?.state === 2 && "text-muted"}`}
        action active={parseInt(docid) === doc.docid}>
        {doc.ownerid === user.userid
          ? <span className="d-flex">
              {doc.signers.map(signer => signer.name).join(", ")}
              <small className="ms-auto text-muted">{formatDate(doc.timecreated)}</small>
            </span>
          : <span className="d-flex">
              {doc.owner.firstname} {doc.owner.lastname}
              <small className="ms-auto text-muted">{formatDate(doc.timecreated)}</small><br />
            </span>}
        <small>{doc.name}</small>
      </ListGroup.Item>)*/}
    </ListGroup>
    <Outlet context={{ docs, setDocs }} />
  </>;
}
