import { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import useWindowDimensions from "../utils";
import { useLocation } from "react-router-dom";

export default function Page({ pdf, index, scale = 1, onMouseMove, onDrop, onClick, onMouseUp, children }) {
  const canvasRef = useRef();
  const [rendered, setRendered] = useState();
  const [timeout, setTimeout] = useState();
  let inscale = 0;

  const { width } = useWindowDimensions();
  const [navbarWidth, setNavbarWidth] = useState(336);
  const location = useLocation();
  const { pathname } = location;
  
  const [isMobile, setIsMobile] = useState(width < 1280);
  
  const calculateWidth = () => {
    let resultWidth = width - navbarWidth - 330;

    if (isMobile) {
      var scaledWidthPercentage = Math.round(scale * 100);

      if (scaledWidthPercentage > 109) {
        resultWidth = width - navbarWidth;
      } else {
        resultWidth = width - navbarWidth;
      }
    }
  
    return `${resultWidth}px`;
  };

  useEffect(() => {
    var extractedPart = pathname.split('/')[1];
    switch(extractedPart) {
      case 'edit':
        if(width < 992){
          setNavbarWidth(45);
        }else if(width < 1280) {
          setNavbarWidth(0);
        }if(width > 1279) {
          setNavbarWidth(35);
        }
        break;
      case 'package':
        if(width < 992){
          setNavbarWidth(45);
        }else if(width < 1280) {
          setNavbarWidth(0);
        }if(width > 1279) {
          setNavbarWidth(35);
        }
        break;
      case 'signer-home':
        if (width < 992) {
          setNavbarWidth(109);
        } 
        else if (width < 1280) {
          setNavbarWidth(365);
        }
        else if (width > 1279) {
          setNavbarWidth(40);
        }
        break;
      default:
        if (width < 992) {
          setNavbarWidth(109);
        } else if (width < 1280) {
          setNavbarWidth(379);
        } else {
          setNavbarWidth(336);
        }
        break;
    }
    if(width < 1280 ){
      setIsMobile(true);
    } 
    if(width > 1279 ){
      setIsMobile(false);
    } 
  }, [width, pathname, scale]);

  useEffect(() => {
    if (timeout) {
      window.clearTimeout(timeout);
      setTimeout(null);
    }
    let t = window.setTimeout(async () => {
      const page = await pdf.getPage(index + 1);
      if (inscale !== scale) {
        inscale = scale;
        const viewport = page.getViewport({ scale: scale });
        const canvas = canvasRef.current;
        const canvasContext = canvas.getContext("2d");
        canvasContext.clearRect(0, 0, canvas.width, canvas.height);
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({ canvasContext, viewport });
      }
      setRendered(true);
      setTimeout(null);
    }, 200);
    setTimeout(t);
  }, [scale]);

  return <>
    <div className="position-relative d-block overflow-x-scroll" style={{ width: calculateWidth() }}
     onMouseMove={onMouseMove} onDrop={onDrop} onClick={onClick} onMouseUp={onMouseUp}
      onDragOver={event => event.preventDefault()}>
      <canvas id={`p${index}`} className="shadow-sm" ref={canvasRef} height="0px" width="0px" />
      {children}
    </div>
    {!rendered && index === 0 && <Container className="text-center">
        <Spinner animation="border" role="status" className="m-auto">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>}
  </>;
}
