import { useEffect, useState } from "react";
import { Link, Outlet, useOutletContext, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Stack from "react-bootstrap/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck,faPenRuler, faXmark, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { inboxWidth, formatDate } from "../utils";
import { useAuth } from "../AuthContext";
import useWindowDimensions from "../utils";

export default function Drafts() {
  const { docid } = useParams();
  const [user] = useAuth();
  const { docs, setDocs, err } = useOutletContext();
  const [thisDocs, setThisDocs] = useState([]);
  const [boxWidth, setBoxWidth] = useState(docid ? 2 : 1);
  const { width } = useWindowDimensions();
  
  const [isMobile, setIsMobile] = useState(width < 1280);
  const [navbarWidth, setNavbarWidth] = useState(336);
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if(width < 1280 ){
      setIsMobile(true);
    } 
    if(width > 1279 ){
      setIsMobile(false);
    } 
    if(width < 992 ){
      setNavbarWidth(65);
    }else {
      setNavbarWidth(336);
    }
  }, [width]);

  useEffect(() => {
    if (boxWidth) setBoxWidth(docid ? 2 : 1)
  }, [docid]);

  useEffect(() => {
    setThisDocs(docs.filter(doc => doc.ownerid === user.userid && doc.state === 0));
  }, [docs]);

  return <>
    <ListGroup style={{ width: isMobile ? `${width - navbarWidth}px` : '' }}  variant="flush" className={
          isMobile && isExpanded ? 
            "bg-white border-end py-2 overflow mb-3 accordion-expand" : 
          isMobile && !isExpanded ? 
          "bg-white border-end py-2 overflow accordion-collapsed mb-3" : 
          "bg-white border-end py-2 overflow-scroll list-group list-group-width"
        }>
      <ListGroup.Item className="d-flex justify-content-between bg-light">
        <div className="sidecard-header1"><FontAwesomeIcon icon={faPenRuler} size="lg" className="mx-2"/>DRAFTS</div>
        <div>
          <Button variant="light" size="sm" className="ms-auto close-btn" as={Link} to="/home"> 
            <FontAwesomeIcon icon={faXmark}/>
          </Button>
          {
            isExpanded ?
              <Button size="sm" className={isMobile ? "ms-auto accordion-btn" : "d-none"} onClick={() => setIsExpanded(!isExpanded)}> 
                <FontAwesomeIcon icon={faChevronUp}/>
              </Button>
            :
              <Button size="sm" className={isMobile ? "ms-auto accordion-btn" : "d-none"} onClick={() => setIsExpanded(!isExpanded)}> 
                <FontAwesomeIcon icon={faChevronDown}/>
              </Button>
          }
        </div>
      </ListGroup.Item>
      {err?.message && <ListGroup.Item>{err?.message}</ListGroup.Item>}
      {thisDocs.map(doc => <DraftItem key={doc.docid} doc={doc} complete={false} summary={boxWidth === 0}  setIsExpanded={setIsExpanded} isExpanded={isExpanded}/>)}
    </ListGroup>
    <Outlet context={{ docs, setDocs }} />
  </>;
}

export function DraftItem({ doc, summary, setIsExpanded, isExpanded  }) {
  const { docid } = useParams();
  const [user] = useAuth();

  return (
    <ListGroup.Item key={doc.docid} as={Link} to={`${doc.docid}`} action onClick={() => setIsExpanded(!isExpanded)}
      active={parseInt(docid) === doc.docid}>
      <Stack direction="horizontal" gap={1} className="overflow-hidden">
      <div className="container">
          <div className="row">
            <div className="col-9 doc-name-text">
              {doc.name}
            </div>
            <div className="col-3 doc-name-text">
              {!summary && <small className="float-right">{formatDate(doc.timecreated)}</small>}
            </div>
          </div>
        </div>
      </Stack>
    </ListGroup.Item>
  );
}
