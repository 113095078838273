import { Link, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

export default function FinishPackage() {
  const navigate = useNavigate();
  const { doc } = useOutletContext();
  const [searchParams] = useSearchParams();

  function Send(){
    return `../send/template?state=${searchParams.get("state")}`
  }

  return (
  <Modal show={true} size="md" onHide={() => navigate("/home")} backdrop="static">
    <Modal.Header className="row text-white bg-black m-0 border-none">
        <h1 className="card-header-text my-2"><span className="title-first-word">TEMPLATE</span> <span className="ml-1">SAVED</span></h1>
    </Modal.Header>
      <Modal.Body>
        <p>Your template has been successfully saved.</p>
        <p>Do you want to send the template to new signers?</p>
    </Modal.Body>
    <Modal.Footer className="bg-light">
      <Button as={Link} to="/home" variant="secondary" className="mr-auto"><FontAwesomeIcon icon={solid("xmark")} /> HOME</Button>
      <Button as={Link} to={Send()} variant="light"><FontAwesomeIcon icon={faPaperPlane} className="mr-1" />SEND</Button>
    </Modal.Footer>
  </Modal>
  );
}
