import { useEffect, useState } from "react";
import { Link, Outlet, useOutletContext, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faBoxArchive,faXmark, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { formatDate, inboxWidth } from "../utils";
import { useAuth } from "../AuthContext";
import useWindowDimensions from "../utils";

export default function Archives() {
  const { docid } = useParams();
  const [user] = useAuth();
  const { docs, setDocs, err } = useOutletContext();
  const [thisDocs, setThisDocs] = useState([]);
  const [boxWidth, setBoxWidth] = useState(docid ? 2 : 1);
  const { width } = useWindowDimensions();
  
  const [isMobile, setIsMobile] = useState(width < 1280);
  const [navbarWidth, setNavbarWidth] = useState(336);
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if(width < 1280 ){
      setIsMobile(true);
    } 
    if(width > 1279 ){
      setIsMobile(false);
    } 
    if(width < 992 ){
      setNavbarWidth(65);
    }else {
      setNavbarWidth(336);
    }
  }, [width]);


  useEffect(() => {
    if (boxWidth) setBoxWidth(docid ? 2 : 1)
  }, [docid]);

  useEffect(() => {
    setThisDocs(docs.filter(doc => doc.state === 3 && doc.signers.find(signer => signer.userid === user.userid)?.state === 3));
  }, [docs]);

  return <>
    <ListGroup style={{ width: isMobile ? `${width - navbarWidth}px` : '' }} variant="flush" className={
          isMobile && isExpanded ? 
            "bg-white border-end py-2 overflow mb-3 accordion-expand" : 
          isMobile && !isExpanded ? 
          "bg-white border-end py-2 overflow accordion-collapsed mb-3" : 
          "bg-white border-end py-2 overflow-scroll list-group list-group-width"
        }>
      <ListGroup.Item className="d-flex  justify-content-between bg-light">
      <div className="sidecard-header1"><FontAwesomeIcon icon={faBoxArchive} size="lg" className="mx-2"/>ARCHIVES </div>
      <div>
        <Button variant="light" size="sm" className="ms-auto close-btn" as={Link} to="/home"> 
          <FontAwesomeIcon icon={faXmark}/>
        </Button>
        {
          isExpanded ?
            <Button size="sm" className={isMobile ? "ms-auto accordion-btn" : "d-none"} onClick={() => setIsExpanded(!isExpanded)}> 
              <FontAwesomeIcon icon={faChevronUp}/>
            </Button>
          :
            <Button size="sm" className={isMobile ? "ms-auto accordion-btn" : "d-none"} onClick={() => setIsExpanded(!isExpanded)}> 
              <FontAwesomeIcon icon={faChevronDown}/>
            </Button>
        }
      </div>
      </ListGroup.Item>
      {err?.message && <ListGroup.Item>{err?.message}</ListGroup.Item>}
      {thisDocs.map(doc => <div className={isMobile ? "d-flex" : ""}><ArchiveItem key={doc.docid} doc={doc} summary={boxWidth === 0}  setIsExpanded={setIsExpanded} isExpanded={isExpanded}/></div>)}
    </ListGroup>
    <Outlet context={{ docs, setDocs }} />
  </>;
}

export function ArchiveItem({ doc, summary, setIsExpanded, isExpanded  }) {
  const { docid } = useParams();
  const [user] = useAuth();  
  const { width } = useWindowDimensions();
  
  const [isMobile, setIsMobile] = useState(width < 1280);

  useEffect(() => {
    if(width < 1280 ){
      setIsMobile(true);
    } 
    if(width > 1279 ){
      setIsMobile(false);
    } 
  }, [width]);


  return (
    <ListGroup.Item  key={doc.docid} as={Link} to={`${doc.docid}`} action onClick={() => setIsExpanded(!isExpanded)}
      active={parseInt(docid) === doc.docid}>
      <Stack className="overflow-hidden">
        <span className="d-flex doc-name-text">
          {doc.name}
          {!summary && <small className="ms-auto text-muted">{formatDate(doc.timesent)}</small>}
        </span>
        <span className="d-flex doc-client-text">
          {doc.ownerid === user.userid
            ? doc.signers.map(signer => signer.name).join(", ")
            : `${doc.owner.firstname} ${doc.owner.lastname}`}
        </span>
      </Stack>
    </ListGroup.Item>
  );
}
