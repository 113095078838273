import { useState } from "react";
import { Link, Outlet, useNavigate, useOutletContext } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faUser } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../AuthContext";

export default function Account() {
  const navigate = useNavigate();
  const [subtitle, setSubtitle] = useState("");

  return (
    <Modal show={true} onHide={() => navigate("..")} size="lg" backdrop="static">
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title>Account {subtitle}</Modal.Title>
      </Modal.Header>
      <Stack direction="horizontal" className="align-items-stretch">
        <ListGroup variant="flush" className="border-end" style={{width: "15rem", minHeight: "20rem"}}>
          <ListGroup.Item action as={Link} to="profile">
            <FontAwesomeIcon icon={faUser} /> Profile
          </ListGroup.Item>
          <ListGroup.Item action as={Link} to="settings">
            <FontAwesomeIcon icon={faCogs} /> Settings
          </ListGroup.Item>
        </ListGroup>
        <Outlet context={{ setSubtitle }} />
      </Stack>
    </Modal>
  );
}
