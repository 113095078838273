import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import { useAuth } from "../AuthContext";
import pfp from "../data/monopoly.png";

export default function Settings() {
  const navigate = useNavigate();
  const { setSubtitle } = useOutletContext();
  const [user, setUser] = useAuth();
  const [firstname, setFirstname] = useState(user.firstname);
  const [lastname, setLastname] = useState(user.lastname);
  const [company, setCompany] = useState(user.company);
  const [err, setErr] = useState("");

  useEffect(() => setSubtitle("- Settings"), []);

  async function handleSubmit(event) {
    event.preventDefault();
  }

  return (
    <Stack>
      <Modal.Body>
        <Form id="DetailsForm" onSubmit={handleSubmit}>
          <p>Settings on its way!</p>
        </Form>
        {err?.message && <Alert variant="danger">{err?.message}</Alert>}
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <Button as={Link} to="/home" variant="light">Cancel</Button>
        <Button variant="dark" className="orange ms-auto" type="submit" form="DetailsForm">Save</Button>
      </Modal.Footer>
    </Stack>
  );
}
