import { useEffect, useRef, useState } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const inputs = [
  "text",
  "paragraph",
  "initial",
  "checkbox",
  "date",
  "signature",
];

export const imgInputs = [
  "signature",
  "initial",
];

export const docStates = [
  "drafts",
  "templates",
  "inbox",
  "archives"
];

export const signFonts = [
  "Babylonica",
  "DancingScript",
  "GreatVibes",
  "Pacifico",
  "Parisienne",
  "Sacramento",
  "Satisfy",
  "Tangerine",
  "Zeyada",
];

export const isImg = input => imgInputs.includes(inputs[input.type]);
export const isCheckbox = input => inputs[input.type] === "checkbox";
export const isInputtype = (input, type) => inputs[input.type] === type;
export const statelabel = (state, signer) => (state === 1) ? signer?.label ?? "signer" : signer?.name ?? "signer";
export const stateLabel = ["name", "label"];
export const stateLabelHelp = [
  "Personal or company name of the recipient.",
  "Type of recipient, ex. Supplier, Client, Manager."
];
export const ratio = input => (inputs[input.type] === "signature") ? 1.6 : 1;

export const drawSizes = (input, { asType = false } = {}) => {
  const type = asType ? input : inputs[input.type];
  switch (type) {
    case "text": 
    case "date":
    case "paragraph":
      return { width: 120, height: 2 * input.fontsize };
    case "checkbox":
      return { width: 80, height: 2 * input.fontsize };
    case "signature":
      return { width: 80, height: 50 };
    case "initial":
      return { width: 55, height: 50 };
    default:
      return { width: 0, height: 0 };
  }
};

export const resizes = {
  min: 15,
  max: 200,
};


export const inboxWidth = ["8rem", "20rem", "15rem"];

export function formatDate(timestr) {
  const date = new Date(Date.parse(timestr));
  const now = new Date();
  if (date.getFullYear() === now.getFullYear()) {
    if (date.getMonth() === now.getMonth() && date.getDate() === now.getDate())
      return date.toLocaleTimeString([], { hour12: false, hour: "numeric", minute: "2-digit" })
    else return date.toLocaleDateString("default", {month: "short", day: "numeric"});
  } else return date.toLocaleDateString();
}

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const capitalize = word => word.charAt(0).toUpperCase() + word.slice(1);
