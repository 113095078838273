import axios from "axios";
import { useState } from "react";
import { Link, Outlet, useLocation, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function Send() {
  const location = useLocation();
  const navigate = useNavigate();
  const { doc, setDoc, setDocs } = useOutletContext();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState();
  const [searchParams] = useSearchParams();

  const sendReceiveAppendText = 'on Agreery';
  const createDocumentAppendText = 'to review and sign';

  async function updateDoc() {
    try {
      let {data: _, ...req} = doc;
      await axios.patch(`${process.env.REACT_APP_API_URL}/docs`, req);
    } catch (err) {
      console.error(err);
      setErr(err.response?.data);
    }
  }

  async function send(event) {
    event.preventDefault();
    try {
      setLoading(true);
      debugger
      if (!doc.signers.length) throw {reason: "noone", message: "Add signers before sending the document!"};
      doc.signers = (await axios.put(`${process.env.REACT_APP_API_URL}/docs/${doc.docid}/signers`, doc.signers)).data;
      var res;
      if(searchParams.get("receive") == "true"){
        res = (await axios.post(`${process.env.REACT_APP_API_URL}/docs/${doc.docid}/send`, {message: message, docState: 3, state: 2, appendDoc: true, appendText: sendReceiveAppendText})).data;
      }
      else {
        res = (await axios.post(`${process.env.REACT_APP_API_URL}/docs/${doc.docid}/send`, {message: message, appendText: createDocumentAppendText})).data;
      }
      let docs = (await axios.get(`${process.env.REACT_APP_API_URL}/docs`)).data;
      setDocs?.(docs);
      let type = "";
      if(res.state == 3){
        type = "signed"
      } else {
        type = "sent"
      }
      navigate(`/home/send/${res.docid}/finish?receive=true&type=`+ type);
    } catch (err) {
      if (err.response) {
        console.error(err);
        setErr(err.response?.data);
      } else {
        console.error(err);
        setErr(err);
      }
    } finally {
      setLoading(false);
    }
  }

  function renderDescription(){
    if(searchParams.get("receive") == "true"){
      doc.state = 2;
      return <p>Please define the names and email addresses of the recipients of this document</p>;
    }
    else
      return <p>Define the emails of users to which this document will be sent. <br /> Check the box on the left to add yourself as that signer.</p>;
  }

  function renderType(){
    if(searchParams.get("receive") == "true"){
      doc.state = 2;
      return "RECEIVERS";
    }
    else
      return "SIGNERS";
  }

  function cancel(){
    if(searchParams.get("receive") == "true")
      return doc?.signers.length ? "..?receive=true" + (searchParams.get("state") != null ? '&state=' + searchParams.get("state") : '') : `/home//${doc?.docid}?receive=true`;
    else
      return doc?.signers.length ? ".." + (searchParams.get("state") != null ? '?state=' + searchParams.get("state") : '') : `/home//${doc?.docid}`;
  }

  return (
    <Modal show={true} size="lg" onHide={() => navigate("..")} backdrop="static" className="rounded-0">
      <Modal.Body className="container p-0">
        <div className="col-12">
          <div className="text-white bg-black">
            <h1 className="card-header-text py-3"><span className="mx-2 header-text-color">SEND </span> DOCUMENT</h1>
          </div>
          <div className="row">
            <div className="custom-box">
              <Form id="SendForm" onSubmit={send} className="mb-3">
                {renderDescription()}
                <h5 className="text-spacing-sml my-3">{renderType()}</h5>
                <div className="overflow-scroll mb-3" style={{ maxHeight: "20rem" }}>
                  <Outlet context={{ doc, setDoc }} />
                </div>
                <h5 className="text-spacing-sml my-3">DOCUMENT DETAILS</h5>
                <FloatingLabel label="Document name" className="mb-3">
                  <Form.Control required placeholder="Document name" className="bg-light" value={doc?.name ?? ""}
                    onChange={event => setDoc({ ...doc, name: event.target.value })} onBlur={updateDoc} />
                </FloatingLabel>
                <Form.Control as="textarea" placeholder="Email message" className="bg-light" style={{ minHeight: "9rem" }}
                  value={message} onChange={event => setMessage(event.target.value)} />
              </Form>
              {err?.message && <Alert variant="danger">{err?.message}</Alert>}
            </div>
          </div>
          <div className="row px-3 py-3">
            <div className="navigation-container">
              <Button as={Link} to={cancel()} variant="info" className="button-general-styling send-general py-2 my-1 btn-auth"><FontAwesomeIcon icon={solid("xmark")} /> CANCEL</Button>
              <Button variant="secondary" className="button-general-styling send-general py-2 my-1 btn-auth" type="submit" form="SendForm"
                disabled={loading || !doc?.signers.length}>
                {loading
                  ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Sending</>
                  : <><FontAwesomeIcon icon={faPaperPlane} /> SEND</>}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
