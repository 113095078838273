import axios from "axios";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Stack from "react-bootstrap/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../AuthContext";
import { inputs, isImg, isInputtype, statelabel } from "../utils";

export default function FillInput({ doc, setDoc, docRef, input, scale = 1, editable }) {
  const navigate = useNavigate();
  const [user] = useAuth();
  const [changed, setChanged] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [currentSigner, setCurrentSigner] = useState();
  const [inputLabel, setInputLabel] = useState("");
  const inputRef = useRef();
  let timeout = null;

  useEffect(() => {
    setCurrentSigner(doc.signers.find(signer => input.signerid === signer.signerid));
    if (editable && inputs[input.type] === "date") {
      input.textdata = (new Date()).toLocaleDateString();
      setDoc({...doc, inputs: doc.inputs.map(inpt => inpt === input ? input : inpt)});
      axios.put(`${process.env.REACT_APP_API_URL}/inputs/`, input);
    }
  }, []);

  useEffect(() => setInputLabel(`${statelabel(doc?.state, currentSigner)}'s ${inputs[input.type]}`), [currentSigner]);

  useEffect(() => setShowMenu(false), [window.location.pathname]);

  function saveInput() {
    if (changed) {
      axios.put(`${process.env.REACT_APP_API_URL}/inputs/`, input);
      setChanged(false);
    }
  }

  function menuEnter(enabled) {
    if (enabled) {
      window.clearTimeout(timeout);
      setShowMenu(true);
    }
  }

  function menuLeave() {
    timeout = window.setTimeout(() => setShowMenu(false), 400);
  }

  return (
    <OverlayTrigger show={showMenu} placement="top" overlay={
      <Popover className="bg-light" body onTouchStart={() => menuEnter(isImg(input))} onMouseEnter={() => menuEnter(isImg(input))} onMouseLeave={menuLeave}>
        <Stack gap={1} className="text-center">
          {input.label ?? `${inputLabel}`}
          {input.required && " (required)"}
          {isImg(input) && 
            <Button as={Link} to="draw" variant="outline-dark" onClick={() => setShowMenu(false)} onTouchStart={() => setShowMenu(false)}>
              Create {inputs[input.type]}
            </Button>}
        </Stack>
      </Popover>}>
      {isImg(input)
        ? <ImgFillInput ref={inputRef} input={input} inputLabel={inputLabel.split("'s")[0] + " - Click here to sign"} editable={editable}
            setChanged={setChanged} scale={scale} doc={doc} setDoc={setDoc} menuEnter={menuEnter}
            menuLeave={menuLeave} saveInput={saveInput} />
        : isInputtype(input, "checkbox")
        ? <CheckboxFillInput ref={inputRef} input={input} inputLabel={inputLabel} editable={editable}
            setChanged={setChanged} scale={scale} doc={doc} setDoc={setDoc} menuEnter={menuEnter}
            menuLeave={menuLeave} saveInput={saveInput} />
        : isInputtype(input, "paragraph")
        ? <ParagraphFillInput ref={inputRef} input={input} inputLabel={inputLabel} editable={editable}
            setChanged={setChanged} scale={scale} doc={doc} setDoc={setDoc} menuEnter={menuEnter}
            menuLeave={menuLeave} saveInput={saveInput} />
        : <TextFillInput ref={inputRef} input={input} inputLabel={inputLabel} editable={editable}
            setChanged={setChanged} scale={scale} doc={doc} setDoc={setDoc} menuEnter={menuEnter}
            menuLeave={menuLeave} saveInput={saveInput} />}
    </OverlayTrigger>
  );
}

const ImgFillInput = forwardRef(({ input, inputLabel, editable, setChanged, scale, doc, setDoc, menuEnter,
  menuLeave, saveInput }, inputRef) => {
  const navigate = useNavigate();
  const [user] = useAuth();

  function setSignature() {
    if ((inputs[input.type] === "signature" && !user.signature)
     || (inputs[input.type] === "initial" && !user.initial)) navigate("draw");
    input.filled = !input.filled;
    setDoc({...doc, inputs: doc.inputs.map(inpt => inpt === input
      ? input : inpt)});
    axios.put(`${process.env.REACT_APP_API_URL}/inputs/`, input);
  }

  return (
    <img id={`i${input.inputid}`} ref={inputRef} tabIndex={0} alt={inputLabel} title={inputLabel}
      width={input.width * scale} height={input.height * scale}
      className={`position-absolute fillinput bg-light opacity-75 rounded border
        ${input.required && "input-borders"}`}
      src={input.filled ? user[inputs[input.type]] : ""}
      style={{left: input.x * scale, top: input.y * scale}}
      {...(editable ? { onClick: setSignature, onMouseEnter: menuEnter, onMouseLeave: menuLeave } : {})} />
  );
});

const TextFillInput = forwardRef(({ input, inputLabel, editable, setChanged, scale, doc, setDoc, menuEnter,
  menuLeave, saveInput }, inputRef) => {

  function setText(event) {
    input.textdata = event.target.value;
    setDoc({...doc, inputs: doc.inputs.map(inpt => inpt === input ? input : inpt)})
    setChanged(true);
  }

  return (
    <Form.Control id={`i${input.inputid}`} ref={inputRef} required={input.required} disabled={!editable}
      className={`position-absolute fillinput opacity-75 ${input.required && "input-borders"} bg-light`}
      style={{width: input.width * scale, left: input.x * scale, top: input.y * scale, fontSize: input.fontsize * scale}}
      placeholder={inputLabel} value={input.textdata ?? ""}
      {...(editable ? { onBlur: saveInput, onChange: setText, onMouseEnter: menuEnter, onMouseLeave: menuLeave } : {})} />
  );
});

const ParagraphFillInput = forwardRef(({ input, inputLabel, editable, setChanged, scale, doc, setDoc, menuEnter,
  menuLeave, saveInput }, inputRef) => {

  function setText(event) {
    input.textdata = event.target.value;
    setDoc({...doc, inputs: doc.inputs.map(inpt => inpt === input ? input : inpt)})
    setChanged(true);
  }

  return (
    <Form.Control as="textarea" id={`i${input.inputid}`} ref={inputRef} required={input.required} disabled={!editable}
      className={`position-absolute fillinput opacity-75 ${input.required && "input-borders"} bg-light`}
      style={{width: input.width * scale, left: input.x * scale, top: input.y * scale,
        height: input.height * scale, fontSize: input.fontsize * scale, resize: "none"}}
      placeholder={inputLabel} value={input.textdata ?? ""}
      {...(editable ? { onBlur: saveInput, onChange: setText, onMouseEnter: menuEnter, onMouseLeave: menuLeave } : {})} />
  );
});

const CheckboxFillInput = forwardRef(({ input, inputLabel, editable, setChanged, scale, doc, setDoc, menuEnter,
  menuLeave, saveInput }, inputRef) => {

  function check(event) {
    input.filled = event.target.checked;
    setDoc({...doc, inputs: doc.inputs.map(inpt => inpt === input ? input : inpt)})
    axios.put(`${process.env.REACT_APP_API_URL}/inputs/`, input);
  }

  return (
    <Form.Check type="checkbox" id={`i${input.inputid}`} ref={inputRef} required={input.required} disabled={!editable}
      className={`position-absolute fillinput opacity-75 ${input.required && "input-borders"}`}
      style={{width: input.width * scale, left: input.x * scale, top: input.y * scale, fontSize: input.fontsize * scale}}
      checked={input.filled ?? false} {...(editable ? { onChange: check, onMouseEnter: menuEnter, onMouseLeave: menuLeave } : {})} />
  );
});
