import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAuth } from "../AuthContext";

export default function RegisterAfterSign() {
  const navigate = useNavigate();
  const [user, setUser] = useAuth();
  const [searchParams] = useSearchParams();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [password, setPassword] = useState("");
  const [confpassword, setConfpassword] = useState("");
  const [err, setErr] = useState("");

  useEffect(() => {
    (async () => {
      try {
        setFirstname(user.firstname ?? "");
        setLastname(user.lastname ?? "");
        setCompany(user.company ?? "")
      } catch (err) {
        console.error(err);
        setErr(err.response?.data);
      }
    })();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      if (password.length < 10) throw {reason: "short", message: "Password should be at least 10 characters!"};
      if (password !== confpassword) throw {reason: "match", message: "Passwords do not match!"};
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/signup`, {
        email: user.email,
        firstname: firstname,
        lastname: lastname,
        company: company,
        password: password
      });
      navigate("/auth/login");
    } catch (err) {
      if (err.response) {
        console.error(err.response?.data);
        setErr(err.response?.data);
      } else {
        console.error(err);
        setErr(err);
      }
    }
  }

  return (
    <div className="container">
      <div className="col-lg-5 col-md-7 col-10 mx-auto">
        <div className="row my-3">
          <div className="row text-white bg-black">
            <h1 className="card-header-text my-3">SIGN UP</h1>
          </div>
          <div className="row bg-white pb-3">
            <Form id="DetailsForm" onSubmit={handleSubmit}>
              <p>Thank you for using Agreery. If you enjoyed the experience, you can sign up by completing your details below.</p>
              <Form.Control className="pt-3 pb-3 mt-4 mb-3 textbox-square-border" type="email" name="email" required placeholder="Email"
                value={user.email} disabled={true} />
              <Form.Control className="pt-3 pb-3 mt-4 mb-3 textbox-square-border" type="text" name="firstname" required placeholder="First name"
                value={firstname} onChange={event => setFirstname(event.target.value)} />
              <Form.Control className="pt-3 pb-3 mt-4 mb-3 textbox-square-border" type="text" name="lastname" required placeholder="Last name"
                value={lastname} onChange={event => setLastname(event.target.value)} />
              <Form.Control className="pt-3 pb-3 mt-4 mb-3 textbox-square-border" type="text" name="company" placeholder="Company name" autoComplete="off"
                value={company} onChange={event => setCompany(event.target.value)} />
              <Form.Control className="pt-3 pb-3 mt-4 mb-3 textbox-square-border" type="password" name="password" required placeholder="Password"
                value={password} onChange={event => setPassword(event.target.value)} />
              <Form.Control className="pt-3 pb-3 mt-4 mb-3 textbox-square-border" type="password" name="confpassword" required placeholder="Confirm password"
                value={confpassword} onChange={event => setConfpassword(event.target.value)} />

              <Button variant="primary" className="float-right button-general-styling my-1" type="submit" form="DetailsForm">SIGN UP</Button>
              {err?.reason === "verified" && <Link to="/auth/login"><Button variant="secondary" className="float-right button-general-styling my-1">LOGIN</Button></Link>}
            </Form>
            {err?.message && <Alert variant="danger">{err?.message}</Alert>}
          </div>
        </div>

        <div className="row slogan-text-verify">
          <p>EVOLVE</p>
        </div>
|
      </div>
    </div>
  );
}
