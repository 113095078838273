import axios from "axios";
import { useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";

export default function Reject() {
  const navigate = useNavigate();
  const { doc, setDocs } = useOutletContext();
  const [message, setMessage] = useState("");
  const [err, setErr] = useState();

  async function reject(event) {
    event.preventDefault();
    if (window.confirm(`Are you sure you want to reject document ${doc?.name}?`)) {
      try {
        let res = (await axios.post(`${process.env.REACT_APP_API_URL}/docs/${doc?.docid}/reject`, {message: message})).data;
        let docs = (await axios.get(`${process.env.REACT_APP_API_URL}/docs`)).data;
        setDocs?.(docs);
        navigate("finish");
      } catch (err) {
        console.error(err);
        setErr(err.response?.data);
      }
    }
  }

  return (
    <Modal show={true} size="lg" onHide={() => navigate("..")} backdrop="static">
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title>Reject document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="RejectForm" onSubmit={reject} className="mb-3">
          <p>Please tell <b>{doc?.owner.firstname} {doc?.owner.lastname}</b> the reason for rejecting his document <b>{doc?.name}</b>.</p>
          <Form.Control as="textarea" placeholder="Reject message" className="bg-light" style={{minHeight: "9rem"}}
            value={message} onChange={event => setMessage(event.target.value)} />
        </Form>
        {err?.message && <Alert variant="danger">{err?.message}</Alert>}
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <Button as={Link} to=".." variant="light" className="me-auto">Cancel</Button>
        <Button variant="danger" type="submit" form="RejectForm">
          <FontAwesomeIcon icon={faBan} /> Reject
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
