import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as pdfjs from "pdfjs-dist/build/pdf";
import Button from "react-bootstrap/Button";
import logo from "../data/logo_main.png";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Navbar from "react-bootstrap/Navbar";
import Stack from "react-bootstrap/Stack";
import Spinner from "react-bootstrap/Spinner";
import { capitalize, inputs, isInputtype, isImg, drawSizes, formatDate, resizes, usePrevious, ratio } from "../utils";
import Page from "./Page";
import EditInput from "./EditInput";
import Image from "react-bootstrap/Image";
import { useAuth } from "../AuthContext";
import useWindowDimensions from "../utils";
import { isSafari } from 'react-device-detect'
import { useSnackbar } from "../SnackbarContext";

export default function Edit() {
  const navigate = useNavigate();
  const { docid } = useParams();
  const [doc, setDoc] = useState();
  const [pdf, setPdf] = useState();
  const [scale, setScale] = useState(1);
  const [currentSigner, setCurrentSigner] = useState();
  const [currentInput, setCurrentInput] = useState(null);
  const [user, setUser] = useAuth();
  const [currentInputType, setCurrentInputType] = useState(inputs[0]);
  const [dragOffset, setDragOffset] = useState([0, 0]);
  const [resizeStart] = useState([0, 0]);
  const [resizeInput, setResizeInput] = useState(null);
  const [fontsize, setFontsize] = useState(9);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState();
  const docRef = useRef();
  const boxRef = useRef();
  const prevInput = usePrevious(currentInput);
  const [searchParams] = useSearchParams();

  const { width, height } = useWindowDimensions();

  const [isMenuVisible, setIsMenuVisible] = useState(width < 992);
  const [isMobile, setIsMobile] = useState(width < 1280);
  const [isHeightAdjusted, setIsHeightAdjusted] = useState(height < 884);

  const showSnackbar = useSnackbar();

  useEffect(() => {
    if(width < 1280 ){
      setIsMobile(true);
      setIsMenuVisible(true);
    } 
    if(width > 1279 ){
      setIsMobile(false);
      setIsMenuVisible(false);
    } 
    console.log(isMobile)
    console.log(isMobile)
  }, [width]);

  useEffect(() => {
    if(height < 884 ){
      setIsHeightAdjusted(true);
    } 
    if(height > 883 ){
      setIsHeightAdjusted(false);
    } 
  }, [height]);
  
  function toggleMenu() {
    setIsMenuVisible(!isMenuVisible);
  }

  useEffect(() => {
    (async () => {
      try {
        const doc = (await axios.get(`${process.env.REACT_APP_API_URL}/docs/${docid}/data`)).data;
        setDoc(doc);
      } catch (err) {
        if (err.response) {
          console.error(err.response);
          setErr(err.response?.data);
        } else {
          console.error(err);
          setErr(err);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (doc && !pdf) (async () => {
      try {
        const pdf = await pdfjs.getDocument(new Uint8Array(doc.data.data)).promise;
        const page = await pdf.getPage(1);
        let scale = document.getElementById('doc').getBoundingClientRect().width / page?.view[2];
        if (scale > 2.2) scale = 2.2;
        if (scale < 0.8) scale = 0.8;
        setScale(scale);
        setPdf(pdf);
        if (doc.signers.length) setCurrentSigner(doc.signers[0]);
        else navigate("signers");
      } catch (err) {
        console.error(err);
        setErr(err)
      }
    })();
  }, [doc]);

  useEffect(() => setCurrentInput(null), [window.location.pathname]);

  const createOffset = (event, input) => {
    if (event.type === "drop") return dragOffset;
    let divider =  isInputtype(input, "checkbox") ? 2 : 1;
    return [input.fontsize * scale / divider, (input.height - input.fontsize) * scale / divider];
  }

  async function logout(event) {
    event.preventDefault();
    try {
      setUser(null);
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`);
      navigate("/auth/login");
    } catch (err) {
      console.error(err.response?.data);
    }
  }

  async function updateDoc() {
    try {
      let {data: _, ...req} = doc;
      await axios.patch(`${process.env.REACT_APP_API_URL}/docs/`, req);
    } catch (err) {
      console.error(err);
    }
  }

  async function createInput(event) {
    try {
      if (currentInput) return;// setCurrentInput(null);
      if (!currentSigner) throw "Signer undefined!";
      if (!currentInputType) throw "No input type selected!";
      const pageNumber = parseInt(event.target.id.substring(1));
      if (null === pageNumber || isNaN(pageNumber)) throw "No page specified!";
      const rect = event.target.getBoundingClientRect();
      let input = {
        inputid: -(doc.inputs.length + 1),
        docid: doc.docid,
        signerid: currentSigner.signerid,
        type: inputs.indexOf(currentInputType),
        page: pageNumber,
        required: true,
        fontsize: fontsize,
      };
      input.width = drawSizes(input).width;
      input.height = drawSizes(input).height;

      let offset = createOffset(event, input);
      input.x = event.clientX - rect.left - offset[0];
      input.y = event.clientY - rect.top - offset[1];
      if (input.x < 0) input.x = 0;
      if (input.x + input.width * scale > event.target.clientWidth)
        input.x = event.target.clientWidth - input.width * scale;
      if (input.y < 0) input.y = 0;
      let heightOffset = isImg(input) ? input.height * scale : 2 * input.fontsize * scale;
      if (input.y + heightOffset > event.target.clientHeight)
        input.y = event.target.clientHeight - heightOffset;
      input.x /= scale;
      input.y /= scale;

      const currentInputs = doc.inputs;
      setDoc({...doc, inputs: [...doc.inputs, input]});
      const res = (await axios.post(`${process.env.REACT_APP_API_URL}/inputs`, input)).data;
      setDoc({...doc, inputs: [...currentInputs, res]});
      setCurrentInput(res);
    } catch (err) {
      console.error(err);
      setErr(err.response?.data);
    }
  }

  async function deleteInputs() {
    if (window.confirm(`Are you sure you want to delete ${doc.inputs.length} inputs?`)) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/docs/${doc.docid}/inputs`);
        setDoc({...doc, inputs: []});
      } catch (err) {
        console.error(err);
        setErr(err.response?.data);
      }
    }
  }

  function changeSigner(event) {
    setCurrentSigner(doc.signers.find(signer => signer.signerid === parseInt(event.target.value)));
    setCurrentInput(null);
  }

  function changeInputType(inputType) {
    setCurrentInputType(inputType);
    setCurrentInput(null);
  }

  function dragInputType(event) {
    const rect = event.target.getBoundingClientRect();
    setDragOffset([event.clientX - rect.left, event.clientY - rect.top]);
  }

  function resize(event) {
    if (resizeInput) {
      let change = (event.clientX - resizeInput.start.clientX) / ratio(resizeInput);
      if (resizeInput.start.width + change * ratio(resizeInput) / scale < resizes.min ||
          resizeInput.start.height + change / scale > resizes.max) return;
      resizeInput.width = resizeInput.start.width + change * ratio(resizeInput) / scale;
      if (isImg(resizeInput)) {
        resizeInput.height = resizeInput.start.height + change / scale;
        resizeInput.y = resizeInput.start.y - change / scale;
      }
      setDoc({...doc, inputs: doc.inputs.map(inpt => inpt.inputid === resizeInput.inputid ? resizeInput : inpt)});
    }
  }

  function endresize(event) {
    if (resizeInput) {
      console.log("endresize");
      axios.put(`${process.env.REACT_APP_API_URL}/inputs/`, resizeInput);
      setResizeInput(null);
    }
  }

  async function finish() {
    setLoading(true);
    try {
      const res = (await axios.post(`${process.env.REACT_APP_API_URL}/docs/${doc.docid}/sign`, { send: false })).data;
      setDoc(res);
      if(searchParams.get("receive")){
        navigate("send/once?receive=true");
      } else if (window.confirm(`Are you sure you are finished editing document ${doc.name}?`)) {
        navigate("finish");
      }
    } catch (error) {
      if (error.response.status === 500) {
        showSnackbar(error.message, "error");
        setTimeout(() => {
          showSnackbar("The file might be corrupted. Please try a different file.", "error");
        }, 3000);
      }
      showSnackbar(error.message, "error");
    }
    setLoading(false);
   
  }

  if (err?.message) return <span>{err?.message} Click <Link to="/home">here</Link> to go back home</span>;
  if (!doc) return <Container className="text-center">
    <Spinner animation="border" role="status" className="m-auto">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </Container>;
  return (
    <Stack direction="horizontal" className="vh-100">
      {/* Navbar to the left */}
      <Navbar as={Stack} gap={0} className={isMenuVisible ? "d-none" : isMobile ? "h-100 bg-black text-main overflow vs-mobile-navbar-template" : "h-100 bg-black text-main vs-navbar overflow" }>
        <Navbar.Brand as={Link} to="/home">
          <Image src={logo} fluid className="p-4" />
        </Navbar.Brand>
        <Stack gap={0} className="align-items-start py-2">
          <div className="w-100 navbar-list-item navbar-list-item-top">
            <Button as={Link} to="/home" variant="black" className="navbar-text">
              <span className="material-icons-outlined sidebar-icon">home</span>
              <span className="navbar-text-span">HOME</span>
            </Button>
          </div>
          <div className="w-100 navbar-list-item">
            <Button as={Link} to="/home" variant="black" className="navbar-text">
              <span className="material-icons-outlined sidebar-icon">arrow_back</span>
              <span className="navbar-text-span">BACK</span>
            </Button>
          </div>
        </Stack>
        <Stack gap={0} className="align-items-start py-2">
          <ListGroup variant="flush">
          <ListGroup.Item className="navbar-text pl-navbar-text btn-black navbar-text-main flex vs-navbar tools-b-border">
              <span className="material-icons-outlined sidebar-icon">build</span>
              <p className="navbar-text-span-headers">TOOLS</p>
            </ListGroup.Item>
            {inputs.map((inputType, index) => (
              <ListGroup.Item key={index} draggable={!isSafari} className="bg-black text-light vs-navbar navbar-tools-pl edit-list-group-item" active={currentInputType === inputType}
                onDragStart={dragInputType} onMouseDown={() => changeInputType(inputType)}>
                {capitalize(inputType)}
              </ListGroup.Item>
            ))}
            </ListGroup>
        </Stack>
        <Stack gap={0} className="align-items-start py-2">
          <ListGroup variant="flush">
            <ListGroup.Item className="navbar-text pl-navbar-text btn-black navbar-text-main flex vs-navbar tools-b-border">
              <span className="material-icons-outlined sidebar-icon">tune</span>
              <p className="navbar-text-span-headers">OPTIONS</p>
            </ListGroup.Item>
            <ListGroup.Item className="bg-black text-light options edit-list-group-item edit-top-border">
             <Form.Group as={Stack} gap={2} direction="horizontal">
                <Form.Label className="my-auto navbar-options-pl">Font size</Form.Label>
                <Form.Control variant="dark" size="sm" type="number" className="ms-auto bg-gray text-light bc-t navbar-fc options-input"
                  style={{width: "7rem"}} min={6} max={40} step={1}
                  value={fontsize} onChange={event => setFontsize(event.target.value)} />
               </Form.Group>
            </ListGroup.Item>
          </ListGroup>
        </Stack>
        <Stack gap={0} className="align-items-start pt-2">
          <div className="w-100 navbar-list-item navbar-list-item-top">
            <Button variant="black-alt" onClick={logout} className="navbar-text">
              <span className="material-icons-outlined sidebar-icon">logout</span>
              <span className="navbar-text-span">LOG OUT</span>
            </Button>
          </div>
        </Stack>
      </Navbar>
      {/* Navbar on the top: */}
      <Stack>
        <Navbar as={Stack} direction="horizontal" gap={2} expand="sm" className={isMobile ? "w-100 bg-main text-white" : "w-100 bg-main text-white ps-5 pe-2"} onClick={() => setCurrentInput(null)}>
          {/* Navbar on the top: Desktop*/}
          <div className={width < 700 || (Math.round(scale * 100) > 105 && isMobile) ? "d-none"  :"d-flex w-100"}>
            <Button className={isMobile ?  "button-general-styling py-1 p-sides bg-gray edit-btn navbar-button-template ml-2" : "d-none"} variant="primary" onClick={toggleMenu}>
              <span className="material-icons-outlined auth-icons m-0">menu</span>
            </Button>
            <div className="">
              <Navbar.Brand as={Form.Control} plaintext className={isMobile ? "text-white p-0 ml-2" : "text-white p-0"} value={doc.name} onChange={event => setDoc({...doc, name: event.target.value})} onBlur={updateDoc} />
            </div>
            <div className={width < 700 ? "d-flex" : "d-flex ml-auto"}>
              <small className="created-time-stamp">Created: {formatDate(doc.timecreated)}</small>
              {loading
                ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Signing</>
                : <Button className="ms-auto button-general-styling py-1 p-sides" variant="primary" type="submit" form="LoginForm" onClick={finish}>
                  <span className="material-icons-outlined auth-icons">flag</span>
                  <span>FINISH</span>
                </Button>}
              <Button className="button-general-styling py-1 p-sides bg-gray edit-btn mr-2" variant="primary" type="submit" form="LoginForm" onClick={deleteInputs}>
                <span className="material-icons-outlined auth-icons">delete_forever</span>
                <span className="c-white">DELETE ALL</span>
              </Button>
              <InputGroup style={{width: "70px", minWidth: "70px", display: "contents"}} className="ml-2 mr-2">
                <Form.Control style={{width: "55px", minWidth: "55px"}} size="sm" type="number" className="bg-light-gray topbar-fc bw-0 br-0" min={80} max={220} step={10} value={Math.round(scale * 100)}
                  onChange={event => setScale(event.target.value / 100)} />
                <InputGroup.Text className="bw-0 br-0 tb-persentage">%</InputGroup.Text>
              </InputGroup>
            </div>
          </div>
          {/* Navbar on the top: Mobile */}
          <div className={width < 700 || (Math.round(scale * 100) > 105 && isMobile) ?  "d-flex w-75" : "d-none"}>
            <div className="d-flex flex-column">
              <Button className={isMobile ?  "button-general-styling py-1 p-sides bg-gray edit-btn navbar-button-template" : "d-none"} variant="primary" onClick={toggleMenu}>
                  <span className="material-icons-outlined auth-icons m-0">menu</span>
              </Button>
              <Navbar.Brand as={Form.Control} plaintext className={isMobile ? "text-white p-0 ml-2 mt-3 mr-9 mb-4" : "text-white p-0"} value={doc.name} onChange={event => setDoc({...doc, name: event.target.value})} onBlur={updateDoc} />
              <div className={width < 750 ? "d-flex flex-row" : "d-flex ml-auto"}> 
                {loading
                  ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Signing</>
                  : <Button className={isMobile ? "button-general-styling  p-sides navbar-button-template p-1" : "button-general-styling py-1 p-sides"} variant="primary" type="submit" form="LoginForm" onClick={finish}>
                    <span className="material-icons-outlined auth-icons">flag</span>
                    <span className={isMobile ? "d-none" : ""}>FINISH</span>
                  </Button>}
                <Button className={isMobile ? "button-general-styling p-sides bg-gray edit-btn navbar-button-template p-1 mr-2" : "button-general-styling py-1 p-sides bg-gray edit-btn"} variant="primary" type="submit" form="LoginForm" onClick={deleteInputs}>
                  <span className="material-icons-outlined auth-icons">delete_forever</span>
                  <span className={isMobile ? "d-none" : "c-white"}>DELETE ALL</span>
                </Button>
                <InputGroup style={{width: "70px", minWidth: "70px", display: "contents"}} className="ml-2 mr-2">
                  <Form.Control style={{width: "55px", minWidth: "55px"}} size="sm" type="number" className="bg-light-gray topbar-fc bw-0 br-0" min={80} max={220} step={10} value={Math.round(scale * 100)}
                    onChange={event => setScale(event.target.value / 100)} />
                  <InputGroup.Text className="bw-0 br-0 tb-persentage">%</InputGroup.Text>
                </InputGroup>
              </div>
            </div>
          </div>
        </Navbar>
        <div className="flex-grow-1 overflow-hidden">
          <Stack direction="horizontal" className="h-100 align-items-stretch">
            <Col className="overflow-scroll" style={{maxHeight: "inherit"}} ref={docRef}>
              <div className="m-4" ref={boxRef} id="doc">
                {pdf && [...Array(pdf.numPages).keys()].map(index => (
                  <Page key={index} pdf={pdf} index={index} scale={scale}
                    onClick={createInput} onMouseMove={resize} onMouseUp={endresize}>
                    {doc.inputs.filter(input => input.page === index).map(input => (
                      <EditInput key={input.inputid} doc={doc} setDoc={setDoc} docRef={docRef} input={input}
                        currentInput={currentInput} setCurrentInput={setCurrentInput} setCurrentSigner={setCurrentSigner}
                        prevInput={prevInput} scale={scale} setResizeInput={setResizeInput} setFontsize={setFontsize} />
                    ))}
                  </Page>
                ))}
              </div>
            </Col>
            <Outlet context={{ doc, setDoc, currentSigner, setCurrentSigner }} />
          </Stack>
        </div>
        <small className="border-top text-muted text-center mt-auto bg-white">
          Copyright &copy; 2020-2023 Agreery | Created by <a href="https://bredellferreira.co.za" target="_blank">
            Bredell Ferreira</a>
        </small>
      </Stack>
    </Stack>
  );
}
