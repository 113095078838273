import axios from "axios";
import { useEffect, useState } from "react";
import { createSearchParams, Link, Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button , Modal } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Navbar from "react-bootstrap/Navbar";
import InputGroup from "react-bootstrap/InputGroup";
import Stack from "react-bootstrap/Stack";
import logo from "../data/logo_main.png";
import logoIcon from "../data/logo_icon.png";
import createDocument from "../data/create_document.png";
import createTemplate from "../data/create_template.png";
import useTemplate from "../data/use_template.png";
import signSend from "../data/sign_and_send.png";
import blank from "../data/blank.png";
import { useAuth } from "../AuthContext";
import useWindowDimensions from "../utils";

export default function SignerHome() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useAuth();
  const [docs, setDocs] = useState([]);
  const [numInbox, setNumInbox] = useState(0);
  const [numOutbox, setNumOutbox] = useState(0);
  const [numDrafts, setNumDrafts] = useState(0);
  const [err, setErr] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const { width } = useWindowDimensions();
  

  const [showContainer, setShowContainer] = useState(true); // State variable to control visibility 

  const [isMobile, setIsMobile] = useState(width < 1280);
  const [isMenuButtonVisible, setMenuButtonVisible] = useState(width > 991);
  const [navbarWidth, setNavbarWidth] = useState(336);

  function toggleMenu() {
    setIsMobile(!isMobile)
  }

  useEffect(() => {
    if(width < 992 ){
      setIsMobile(true);
      setMenuButtonVisible(true);
    } 
    if(width > 991 ){
      setIsMobile(false);
      setMenuButtonVisible(false);
    } 
    if(width < 1280 ){
      setNavbarWidth(65);
    }else {
      setNavbarWidth(336);
    }
  }, [width]);

  useEffect(() => {
    (async () => {
      try {
        let docs = (await axios.get(`${process.env.REACT_APP_API_URL}/docs`)).data;
        setDocs(docs);
      } catch (err) {
        console.log(err.response);
        setErr(err.response?.data);
      }
    })();
  }, []);

  useEffect(() => {
    setNumInbox(docs.filter(doc => doc.ownerid !== user.userid && [2, 3].includes(doc.state)
      && doc.signers.find(signer => signer.userid === user.userid)?.state === 1).length);
    setNumOutbox(docs.filter(doc => doc.ownerid === user.userid && [2, 3].includes(doc.state)
      && doc.signers.find(signer => signer.userid === user.userid)?.state === 1).length);
    setNumDrafts(docs.filter(doc => doc.ownerid === user.userid && doc.state === 0).length);
  }, [docs]);

  useEffect(() => {
    // Check if the current location includes "/home"
    if (location.pathname == "/home") {
      setShowContainer(true); // Show the container if the path includes "/home"
    } else {
      setShowContainer(false); // Hide the container for other routes
    }
  }, [location]);

  const showSignUpPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  async function logout(event) {
    event.preventDefault();
    try {
      setUser(null);
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`);
      navigate("/auth/login");
    } catch (err) {
      console.error(err.response?.data);
    }
  }

  function search(event) {
    let query = event.target.value;
    if (query.length) {
      if (!location?.pathname.includes("search")) navigate({
        pathname: "search",
        search: createSearchParams({ name: query }).toString()
      });
      else setSearchParams({ name: query });
    } else {
      setSearchParams({});
      navigate(".");
    }
  }

  return (
    <Stack direction="horizontal" className="vh-100">
      {/* Navbar to the left */}
      <Navbar as={Stack} gap={0} className={!isMobile ? "h-100 bg-black text-main vs-navbar overflow-y-scroll overflow-x-hidden" : "d-none"}>
        <div className={isMenuButtonVisible ? "navbar-list-item d-flex justify-content-end w-100" : "d-none"}>
          <Button variant="dark-blue" onClick={ toggleMenu } className="navbar-text">
            <span className="material-icons-outlined sidebar-icon">menu</span>
          </Button>
        </div>
        <Navbar.Brand as={Link} to="/home">
          <Image src={logo} fluid className="p-4" />
        </Navbar.Brand>
        <Stack gap={0} className="align-items-start py-2">
          <div className="w-100 navbar-list-item">
            <Button as={Link} to="/home" variant="black" className="navbar-text">
              <span className="material-icons-outlined sidebar-icon">home</span>
              <span className="navbar-text-span">SIGN UP</span>
            </Button>
          </div>
          <div className="w-100 navbar-list-item">
            <Button variant="black" className="w-100 navbar-text">
              <span className="material-icons-outlined sidebar-icon">mark_email_read</span>
              <span className="navbar-text-span">PENDING</span>
              <div variant="dark" className=" ml-auto pe-2 ps-2 as-c bg-dark text-light">
                {numInbox}
              </div>
            </Button>
          </div>
          <div className="w-100 navbar-list-item">
            <Button variant="black" className="w-100 navbar-text" >
              <span className="material-icons-outlined sidebar-icon">send</span>
              <span className="navbar-text-span">COMPLETED</span>
              <div variant="dark" className="ml-auto pe-2 ps-2 as-c bg-dark text-light">
                {numOutbox}
              </div>
            </Button>
          </div>
          <div className="w-100 navbar-list-item">
            <Button variant="black" className="navbar-text" title="Not logged in" onClick={showSignUpPopup}>
              <span className="material-icons-outlined sidebar-icon">edit_note</span>
              <span className="navbar-text-span">DRAFTS</span>
            </Button>
          </div>
          <div className="w-100 navbar-list-item">
            <Button variant="black" className="navbar-text" title="Not logged in" onClick={showSignUpPopup}>
              <span className="material-icons-outlined sidebar-icon">file_copy</span>
              <span className="navbar-text-span">TEMPLATES</span>
            </Button>
          </div>
          <div className="w-100 navbar-list-item">
            <Button variant="black" className="navbar-text" title="Not logged in" onClick={showSignUpPopup}>
              <span className="material-icons-outlined sidebar-icon">inventory_2</span>
              <span className="navbar-text-span">ARCHIVES</span>
            </Button>
          </div>
        </Stack>
        <Stack gap={0} className="align-items-start py-2">
          <div className="w-100 navbar-list-item">
            <Button variant="black" className="navbar-text" title="Not logged in" onClick={showSignUpPopup}>
              <span className="material-icons-outlined sidebar-icon">security</span>
              <span className="navbar-text-span">VALIDATE</span>
            </Button>
          </div>
          <div className="w-100 navbar-list-item">
            <Button variant="black" className="navbar-text" title="Not logged in" onClick={showSignUpPopup}>
              <span className="material-icons-outlined sidebar-icon">person</span>
              <span className="navbar-text-span">PROFILE</span>
            </Button>
          </div>
          <div className="w-100 navbar-list-item">
            <Button variant="black" className="navbar-text" title="Not logged in" onClick={showSignUpPopup}>
              <span className="material-icons-outlined sidebar-icon">settings</span>
              <span className="navbar-text-span">SETTINGS</span>
            </Button>
          </div>
          <div className="w-100 navbar-list-item">
            <Button variant="black-alt" className="navbar-text" title="Not logged in" onClick={showSignUpPopup}>
              <span className="material-icons-outlined sidebar-icon">logout</span>
              <span className="navbar-text-span">LOG OUT</span>
            </Button>
          </div>
        </Stack> 
        <Stack gap={0} className="bg-grey text-white text-centerpy-2">
          <Image src={blank} roundedCircle style={{ width: "5rem" }} className="mx-auto py-3" />
          <p className="mx-auto headings-accent">{user.email.toUpperCase()}</p>
        </Stack>    
      </Navbar>

      {/* mobile navbar on the side  */}
      <Navbar as={Stack} gap={0} className={isMobile ? "h-100 bg-black text-main vs-mobile-navbar overflow-y-scroll overflow-x-hidden" : "d-none"}>
        <div className="w-10 navbar-list-item">
          <Button variant="dark-blue" onClick={ toggleMenu } className={isMobile? 'navbar-text' : 'd-none'}>
            <span className="material-icons-outlined sidebar-icon">menu</span>
          </Button>
        </div>
        <Stack gap={0} className="align-items-start py-2">
          <div className="w-100 navbar-list-item">
            <Button as={Link} to="/home" variant="black" className="navbar-text">
              <span className="material-icons-outlined sidebar-icon">home</span>
            </Button>
          </div>
          <div className="w-100 navbar-list-item">
            <Button variant="black" className="navbar-text">
              <span className="material-icons-outlined sidebar-icon">mark_email_read</span>
            </Button>
          </div>
          <div className="w-100 navbar-list-item">
            <Button variant="black" className="navbar-text" >
              <span className="material-icons-outlined sidebar-icon">send</span>
            </Button>
          </div>
          <div className="w-100 navbar-list-item">
            <Button variant="black" className="navbar-text" title="Not logged in" onClick={showSignUpPopup}>
              <span className="material-icons-outlined sidebar-icon">edit_note</span>
            </Button>
          </div>
          <div className="w-100 navbar-list-item">
            <Button variant="black" className="navbar-text" title="Not logged in" onClick={showSignUpPopup}>
              <span className="material-icons-outlined sidebar-icon">file_copy</span>
            </Button>
          </div>
          <div className="w-100 navbar-list-item">
            <Button variant="black" className="navbar-text" title="Not logged in" onClick={showSignUpPopup}>
              <span className="material-icons-outlined sidebar-icon">inventory_2</span>
            </Button>
          </div>
        </Stack>
        <Stack gap={0} className="align-items-start py-2">
          <div className="w-100 navbar-list-item">
            <Button variant="black" className="navbar-text" title="Not logged in" onClick={showSignUpPopup}>
              <span className="material-icons-outlined sidebar-icon">security</span>
            </Button>
          </div>
          <div className="w-100 navbar-list-item">
            <Button variant="black" className="navbar-text" title="Not logged in" onClick={showSignUpPopup}>
              <span className="material-icons-outlined sidebar-icon">person</span>
            </Button>
          </div>
          <div className="w-100 navbar-list-item">
            <Button variant="black" className="navbar-text" title="Not logged in" onClick={showSignUpPopup}>
              <span className="material-icons-outlined sidebar-icon">settings</span>
            </Button>
          </div>
          <div className="w-100 navbar-list-item">
            <Button variant="black-alt" className="navbar-text" title="Not logged in" onClick={showSignUpPopup}>
              <span className="material-icons-outlined sidebar-icon">logout</span>
            </Button>
          </div>
        </Stack>    
      </Navbar>
      {/* Navbar on the top: */}
      <Stack>
        <Navbar className="w-100 bg-main text-white ps-5 pe-2">
          <h3 className="me-auto welcome-text">WELCOME</h3>
          <Button variant="light mr-2 btn-padding">
            <span className="material-icons-outlined">live_help</span>
          </Button>
        </Navbar>
        <Stack direction="horizontal" className="h-100 align-items-stretch overflow-y-scroll overflow-x-hidden">
          <Outlet context={{ docs, setDocs, err, setErr }} />
        </Stack>
      </Stack>
      <Modal show={showPopup} onHide={handleClosePopup} backdrop="static">
        <Modal.Header className="bg-light">
          <Modal.Title>Access Denied</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please sign up to access this feature.</p>
        </Modal.Body>
        <Modal.Footer className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col">
              <Button as={Link} to="/signer-home/inbox" variant="light" onClick={handleClosePopup}>
               Close
              </Button>
            </div>
          <div className="col text-end">
          <Button as={Link} to="/home" variant="light" onClick={handleClosePopup}>
            Sign up
          </Button>
        </div>
      </div>
    </div>
  </Modal.Footer>
      </Modal>
    </Stack>
  );
}
