import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../AuthContext";
import { docStates, stateLabel } from "../utils";
import useWindowDimensions from "../utils";

export default function Signers() {
  const navigate = useNavigate();
  const [user] = useAuth();
  const { doc, setDoc, currentSigner, setCurrentSigner } = useOutletContext();
  const [err, setErr] = useState();
  const [searchParams] = useSearchParams();
  
  const { width } = useWindowDimensions();

  const [isMobileView, setIsMobileView] = useState(width < 495);

  useEffect(() => {
    if(width < 495 ){
      setIsMobileView(true);
    } 
    if(width > 494 ){
      setIsMobileView(false);
    } 
  }, [width]);


  async function updateDoc(event) {
    event.preventDefault();
    try {
      if (!doc.signers.length) throw {reason: "none", message: "You have to add at least one signer!"}
      doc.signers = (await axios.put(`${process.env.REACT_APP_API_URL}/docs/${doc.docid}/signers`, doc.signers)).data;
      const signerids = doc.signers.map(signer => signer.signerid);
      doc.inputs = doc.inputs.filter(input => signerids.includes(input.signerid));
      setDoc(doc);
      if (!signerids.includes(currentSigner?.signerid)) setCurrentSigner(doc.signers[0]);
      if(searchParams.get("template")){
        navigate(`/package/${doc.docid}?template=${searchParams.get("template")}&state=${searchParams.get("state")}`);
      }
      else {
        navigate(`/package/${doc.docid}?state=${searchParams.get("state")}`);
      }
    } catch (err) {
      if (err.response) {
        console.error(err.response);
        setErr(err.response?.data);
      } else {
        console.error(err);
        setErr(err);
      }
    }
  }

  function selfcheck(event, signer) {
    if (event.target.checked) {
      setDoc({...doc, signers: doc.signers.map(s => s === signer
        ? {...signer,
            label: user.company.length ? user.company : user.firstname,
            name: `${user.firstname} ${user.lastname}`,
            email: user.email
        }
        : s.email === user.email ? {...s, label: "", name: "", email: ""} : s)});
    } else {
      setDoc({...doc, signers: doc.signers.map(s => s === signer
        ? {...signer, label: "", name: "", email: ""} : s)});
    }
  }

  function setName(event, signer, index) {
    signer[stateLabelName()] = event.target.value;

    const updatedSigners = doc.signers.map((s, i) => i === index ? signer : s);
  
    setDoc({...doc, signers: updatedSigners});
  }

  function setDescription(){
    if(searchParams.get("template") === "true")
      return "Please define the labels of the signers of this document. E.g. Signer 1, Signer 2, Signer 3 or Client, Witness, Attorney."
    else
      return "Enter the names of the signers of the document."
  }

  function cancel(){
    if(searchParams.get("template") === "true")
      return doc?.signers.length ? `/package/${doc?.docid}?template=true&state=${searchParams.get("state")}` : `/home/${docStates[doc?.state]}/${doc?.docid}`;
    else
      return doc?.signers.length ? `/package/${doc?.docid}?state=${searchParams.get("state")}` : `/home/${docStates[doc?.state]}/${doc?.docid}`;
  }

  function stateLabelName(){
    var paramaters = searchParams.get("state");
    return stateLabel[paramaters];
  }

  function title(){
    if(searchParams.get("state") === "0")
      return "ADD SIGNERS";
    else
      return "ADD SIGNER LABELS";
  }

  return (
    <Modal show={true} onHide={() => navigate("..")} backdrop="static">
      <Modal.Body className="container p-0">
        <div className="col-12">
          <div className="text-white bg-black">
            <h1 className="card-header-text py-3">{title()}</h1>
          </div>
          <div className="row">
            <div className="custom-box">
              <Form id="SignerForm" onSubmit={updateDoc} className="mb-3">
                <p>
                  {setDescription()}<br/>Check the box to add yourself as a signer.
                </p>
                {doc.signers.map((signer, index) => 
                <Stack key={index} direction="horizontal" className="mb-2" gap={2}>
                  <Form.Check name="self" type="checkbox" aria-label="self" checked={signer.email === user.email}
                    onChange={event => selfcheck(event, signer)} />
                  <Form.Control required placeholder={`Signer ${stateLabelName()}`} className="pt-3 pb-3 mt-2 mb-1 textbox-square-border" autoFocus
                    value={signer[stateLabelName()]} onChange={event => setName(event, signer, index)} />
                  <Button variant="light" onClick={() => setDoc({ ...doc, signers: doc.signers.filter(s => s !== signer) })}>
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </Stack>)}
                <Button className="ml-1" onClick={() => setDoc({
                  ...doc, signers: [...doc.signers, {
                    label: "",
                    name: "",
                    email: ""
                  }]
                })} variant="secondary">
                  <FontAwesomeIcon icon={faPlus} /> ADD
                </Button>
                {err?.message && <Alert variant="danger">{err?.message}</Alert>}
              </Form>
            </div>
          </div>
          <div className="row px-3 py-2">
            <div className={isMobileView ? "d-flex flex-column" : ""}>
              {!!doc?.signers.length && <Button className={isMobileView ? "button-general-styling-mobile p" : "float-right button-general-styling p"} variant="primary" type="submit" form="SignerForm">CONTINUE</Button>}
              <Button className={isMobileView ? "button-general-styling-mobile mt-2" : "float-right button-general-styling"} as={Link} to={cancel()} variant="secondary">CANCEL</Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}