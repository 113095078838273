import axios from "axios";
import { useEffect, useState } from "react";
import { useOutletContext, useParams, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Stack from "react-bootstrap/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const docTypes = [
  {
    title: "Edit",
    icon: solid("pen-ruler"),
    text: "Quickly edit, fill and sign a pdf document.",
    variant: "outline",
  },
  {
    title: "Send",
    icon: solid("paper-plane"),
    text: "Edit and send pdf document to another party to sign.",
    variant: "outline-primary",
  }
]

export default function CreateDoc() {
  const navigate = useNavigate();
  const { docid } = useParams();
  const [doc, setDoc] = useState();
  const { setErr } = useOutletContext();

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.get(`${process.env.REACT_APP_API_URL}/docs/${docid}`);
        setDoc(res.data);
      } catch (err) {
        console.error(err.response);
        setErr(err.response?.data);
      }
    })();
  }, []);

  async function updateDoc(event) {
    event.preventDefault();
    try {
      let res = await axios.patch(`${process.env.REACT_APP_API_URL}/docs`, doc);
      if (0 === doc.state) {
        navigate(`/edit/${res.data.docid}`);
      } else {
        navigate(`/package/${res.data.docid}/signers`);
      }
    } catch (err) {
      console.error(err.response);
      setErr(err.response?.data);
    }
  }

  return <>
    <Form id="UploadForm" className="mb-3 d-flex flex-column" onSubmit={updateDoc}>
      <h4>Document details</h4>
      <FloatingLabel label="Document name" className="mb-3">
        <Form.Control type="text" name="name" required placeholder="Document name"
         value={doc?.name ?? ""} onChange={event => setDoc({...doc, name: event.target.value})} />
      </FloatingLabel>
      <Stack gap={2} direction="horizontal" className="text-center mx-auto">
        {docTypes.map((docType, index) => (
          <Card key={index} as={Button} variant={docType.variant} style={{height: "10rem", width: "16rem"}} active={doc?.state === index}
            onClick={() => setDoc({...doc, state: index})}>
            <Card.Body>
              <Card.Title>{docType.title}</Card.Title>
              <FontAwesomeIcon icon={docType.icon} size="xl" />
              <Card.Text>{docType.text}</Card.Text>
            </Card.Body>
          </Card>
        ))}
      </Stack>
    </Form>
  </>;
}
