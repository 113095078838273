import axios from "axios";
import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import UploadBox from "./UploadBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function Create() {
  const navigate = useNavigate();
  const { setTitle, setFooter } = useOutletContext();
  const { docid } = useParams();
  const [err, setErr] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => setTitle("UPLOAD NEW DOCUMENT"), []);

  async function createCallback(event, file) {
    event.preventDefault();
    event.stopPropagation();
    try {
      if (!file) throw { reason: "none", message: "No file found!" };
      if (file?.type !== "application/pdf") throw { reason: "incorrect", message: "File should be in .pdf format" };
      let body = new FormData();
      body.append("file", file);
      if(searchParams.get("template")){
        body.append("state", 1);
      }
      if(searchParams.get("signAndSend")){
        body.append("state", 3);
      }
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/docs`, body, {
        headers: { "Content-Type": "multipart/form-data" }
      });
      if(searchParams.get("signAndSend")){
        return navigate(`/edit/${res.data.docid}?receive=true`);
      }
      let paramaters = "";
      if(searchParams.get("template")){
        paramaters = `?template=${searchParams.get("template")}&state=1`;
      }
      else {
        paramaters += `?state=0`;
      }
      return navigate(`/package/${res.data.docid}/signers${paramaters}`);
    } catch (err) {
      console.error(err);
      setErr(err.response?.data ?? err);
    }
  }

  return <>
    <Modal.Body as={Stack} gap={1}>
      <UploadBox callback={createCallback} />
      <Outlet context={{ setErr }} />
      {err?.message && <Alert variant="danger" className="mt-3">{err?.message}</Alert>}
    </Modal.Body>
    <Modal.Footer className="bg-light">
      {docid ? <Button variant="dark" type="submit" form="UploadForm" className="mr-auto"> Continue</Button> : ""}
      <Button as={Link} to="/home" className="bg-gray-button"><FontAwesomeIcon icon={solid("xmark")} /> Cancel</Button>
    </Modal.Footer>
  </>;
}
