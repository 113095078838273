import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import logo from "../data/logo_icon.png";
import useWindowDimensions from "../utils";

export default function UploadBox({ callback }) {
  const { docid } = useParams();
  const [borderState, setBorderState] = useState("border-dark");

  const { width } = useWindowDimensions();

  const [isMobileView, setIsMobileView] = useState(width < 576);

  useEffect(() => {
    if(width < 576 ){
      setIsMobileView(true);
    } 
    if(width > 575 ){
      setIsMobileView(false);
    } 
  }, [width]);

  return (
    <Container className={`d-flex ${docid ? "bg-light" : ""} ${borderState}`}
      style={{height: "15rem", border: "2px dashed"}}
      onDragEnter={() => setBorderState("border-warning")}
      onDragOver={event => {event.preventDefault(); setBorderState("border-warning")}}
      onDragLeave={() => setBorderState("")}
      onDrop={event => callback(event, event.dataTransfer.files[0])}>
      <span className={isMobileView ? "popup-mobile" : "d-flex"}>
        <img src={logo} className={isMobileView ? "d-none" : "w-25 my-4 ml-30"}></img>
        {docid ? <>Document uploaded!</> : <Stack className="justify-content-center flex-inherit">
          <span className="align-self-center">Drag and drop or
          <Form.Group className="ms-2">
            <Form.Control id="browse" className="uploadHidden" type="file" accept="application/pdf"
              onChange={event => callback(event, event.target.files[0])} />
            <Form.Label htmlFor="browse" className="btn btn-light mb-0 mt-3">browse</Form.Label>
          </Form.Group>
          </span>
        <small className="mt-4">Maximum upload size is 25MB</small>
        </Stack>}
      </span>
    </Container>
  );
}
