import React, { useContext } from 'react';
import { useNavigate, useOutletContext } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useAuth } from "../AuthContext";

export default function FinishSign() {
  const navigate = useNavigate();
  const { doc } = useOutletContext();
  const [user] = useAuth();
  

  const handleClose = () => {
    if (!user.company) {
      navigate(`/auth/signup/register-after-sign`);
    } else {
      navigate("/home");
    }
  };

  return (
    <Modal show={true} onHide={handleClose} backdrop="static">
      <Modal.Header className="bg-light">
        <Modal.Title>Document signed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Thank you for signing <b>{doc?.name}</b>.</p>
        {doc?.state === 3 ? (
          <p>The document is complete and can be viewed <a href={`/home/inbox/${doc?.docid}`}>here</a>.</p>
        ) : (
          //<p>{doc?.signers.filter(signer => signer.state === 1).map(signer => signer.name).join(", ")} still have to sign.</p>
          <p>Others still have to sign.</p>
        )}
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <Button variant="light" className="me-auto" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
