import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import { useAuth } from "../AuthContext";
import pfp from "../data/monopoly.png";

export default function Profile() {
  const navigate = useNavigate();
  const { setSubtitle } = useOutletContext();
  const [user, setUser] = useAuth();
  const [firstname, setFirstname] = useState(user.firstname);
  const [lastname, setLastname] = useState(user.lastname);
  const [company, setCompany] = useState(user.company);
  const [err, setErr] = useState("");

  useEffect(() => setSubtitle("- Profile"), []);

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      let res = await axios.patch(`${process.env.REACT_APP_API_URL}/users`, {
        firstname: firstname,
        lastname: lastname,
        company: company
      });
      setUser(res.data);
      navigate("/home");
    } catch (err) {
      console.error(err.response?.data);
      setErr(err.response?.data);
    }
  }

  async function deleteAccount(event) {
    event.preventDefault();
    try {
      if (window.confirm("Are you sure you want to delete your account? All documents will be lost!")) {
        await axios.delete(`${process.env.REACT_APP_API_URL}/users`);
        setUser(null);
        navigate("/auth/login");
      }
    } catch (err) {
      console.error(err.response?.data);
      setErr(err.response?.data);
    }
  }

  return (
    <Stack>
      <Modal.Body>
        <Form id="DetailsForm" onSubmit={handleSubmit}>
          <p>Your details are kept confidential and will never be shared externally, but are required to validate your identity on the app.</p>
          <FloatingLabel label="Email address" className="mb-3">
            <Form.Control type="email" name="email" required placeholder="Email"
             value={user.email} disabled={true} />
          </FloatingLabel>
          <FloatingLabel label="First name" className="mb-3">
            <Form.Control type="text" name="firstname" required placeholder="First name"
             value={firstname} onChange={event => setFirstname(event.target.value)} />
          </FloatingLabel>
          <FloatingLabel label="Last name" className="mb-3">
            <Form.Control type="text" name="lastname" required placeholder="Last name"
             value={lastname} onChange={event => setLastname(event.target.value)} />
          </FloatingLabel>
          <FloatingLabel label="Company name" className="mb-3">
            <Form.Control type="text" name="company" placeholder="Company name"
             value={company} onChange={event => setCompany(event.target.value)} />
          </FloatingLabel>
          <Button variant="outline-danger" onClick={deleteAccount}>
            Delete Account
          </Button>
        </Form>
        {err?.message && <Alert variant="danger">{err?.message}</Alert>}
      </Modal.Body>
      <Modal.Footer className="bg-light w-100 d-flex justify-content-between">
        <Button as={Link} to="/home" variant="light" className="btn-popup">Cancel</Button>
        <Button variant="dark" className="orange btn-popup" type="submit" form="DetailsForm">Save</Button>
      </Modal.Footer>
    </Stack>
  );
}
