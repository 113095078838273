import React from "react";
import ReactDOM from "react-dom/client";
import AuthContext from "./AuthContext";
import axios from "axios";
import { BrowserRouter, HashRouter } from "react-router-dom";
import UserContextProvider from "./UserContext";
import 'material-icons/iconfont/material-icons.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import { SnackbarProvider } from "./SnackbarContext";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <React.StrictMode>
      <AuthContext>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </AuthContext>
    </React.StrictMode>
  </HashRouter>
);
