import axios from "axios";
import { useState } from "react";
import { createSearchParams, Link, useNavigate, useSearchParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAuth } from "../AuthContext";
import logo from "../files/images/logo_tagline.png";


export default function ForgotReset() {
  const navigate = useNavigate();
  const [user, setUser] = useAuth();
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [confpassword, setConfpassword] = useState("");
  const [err, setErr] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      if (password.length < 10) throw { reason: "short", message: "Password should be at least 10 characters!" };
      if (password !== confpassword) throw { reason: "match", message: "Passwords do not match!" };
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot/reset`, {
        email: searchParams.get("email"),
        password: password
      });
      setUser(res.data);
      navigate("/home");
    } catch (err) {
      if (err.response) {
        console.error(err.response?.data);
        setErr(err.response?.data);
      } else {
        console.error(err);
        setErr(err);
      }
    }
  }

  return (
    <div className="container">
      <div className="col-lg-5 col-md-7 col-10 mx-auto">
        <div className="row">
          <img src={logo} className="logo-image my-5"></img>
        </div>
        <div className="row">
          <div className="row text-white bg-black">
            <h1 className="card-header-text my-3">RESET PASSWORD</h1>
          </div>
          <div className="row bg-white">
            <Form id="DetailsForm" onSubmit={handleSubmit}>
              <Form.Control className="pt-3 pb-3 mt-4 mb-3 textbox-square-border" type="email" name="email" required placeholder="Email"
                value={searchParams.get("email") ?? ""} disabled={true} />
              <Form.Control className="pt-3 pb-3 mt-4 mb-3 textbox-square-border"  type="password" name="password" required placeholder="Password"
                value={password} onChange={event => setPassword(event.target.value)} />
              <Form.Control className="pt-3 pb-3 mt-4 mb-3 textbox-square-border"  type="password" name="confpassword" required placeholder="Confirm password"
                value={confpassword} onChange={event => setConfpassword(event.target.value)} />
             { err?.message && <Alert variant="danger">{err?.message}</Alert> }
            <div className="row pb-3">
              <div>
                <Button className="float-right button-general-styling btn-auth" variant="primary" type="submit" form="DetailsForm">RESET</Button>
              </div>
            </div>
            </Form>
          </div>
        </div>
        <div className="row slogan-text">
          <p>EVOLVE</p>
        </div>
      </div>
    </div>
  );
}
