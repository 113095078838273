import axios from "axios";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Stack from "react-bootstrap/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faMagic, faPaperPlane, faUndo, faUpload } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../AuthContext";
import { drawSizes, signFonts } from "../utils";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { isMobile } from 'react-device-detect';

export default function Draw() {
  const navigate = useNavigate();
  const [user, setUser] = useAuth();
  const [err, setErr] = useState();
  const [loading, setLoading] = useState(false);
  const signatureRef = useRef();
  const initialRef = useRef();
  const [searchParams] = useSearchParams();

  async function save(event) {
    event.preventDefault();
    try {
      setLoading(true);
      user.signature = signatureRef.current.toDataURL("image/png");
      user.initial = initialRef.current.toDataURL("image/png");
      const res = (await axios.put(`${process.env.REACT_APP_API_URL}/users/draw`, user)).data;
      setUser(res);
      navigate("..");
    } catch (err) {
      console.error(err);
      setErr(err.response?.data);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal show={true} size="lg" onHide={() => navigate("..")} backdrop="static">
      <Modal.Header className="row text-white bg-black m-0 border-none">
          <h1 className="card-header-text my-2"> <span className="text-truncate"><span className="title-first-word mr-1">CREATE</span>SIGNATURE</span></h1>
      </Modal.Header>
      <Modal.Body>
        <Row className="px-2 mb-3">
          <DrawItem ref={signatureRef} title="Signature" type="signature" setErr={setErr} />
          <DrawItem ref={initialRef} title="Initial" type="initial" setErr={setErr} />
        </Row>
        {err?.message && <Alert variant="danger">{err?.message}</Alert>}
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <Button as={Link} to=".." className="me-auto bg-secondary"><FontAwesomeIcon icon={solid("xmark")} /> CANCEL</Button>
        <Button variant="dark" className="orange d-flex" onClick={save} disabled={loading}>
          {loading
            ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> SAVING</>
            : <><span className="material-icons-outlined fs-18 mr-1">send</span> <span className="sign-text-span">SAVE</span></>}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const DrawItem = forwardRef(function DrawItem({ title, type, setErr }, imgRef) {
  const [user, setUser] = useAuth();
  const [genContent, setGenContent] = useState();
  const [drawParams, setDrawParams] = useState({});
  const scale = 2;
  const imgScale = 10;
  let start = [0, 0];
  let rect = null;
  let stroke = 4;
  let imgSize = drawSizes(type, { asType: true });

  useEffect(() => {
    if (user[type]) {
      const image = new Image(imgSize.width * imgScale, imgSize.height * imgScale);
      image.imageRendering = "crisp-edges";
      image.src = user[type];
      image.onload = () => imgRef.current.getContext("2d").drawImage(image, 0, 0,
        imgSize.width * imgScale, imgSize.height * imgScale);
    }
  }, []);

  useEffect(() => {
    if(user.lastname)
      setGenContent(type === "initial"
        ? `${user.firstname.charAt(0)}. ${user.lastname?.charAt(0)}.`
        : `${user.firstname} ${user.lastname}`);
    else 
      setGenContent(type === "initial"
        ? `${user.firstname.charAt(0)}.`
        : `${user.firstname}`);
  }, [user]);

  function draw(event) {
    event.stopPropagation();
    let ctx = imgRef.current.getContext("2d");
    if (!rect) {
      rect = imgRef.current.getBoundingClientRect();
      start = [(event.clientX - rect.left) * scale, (event.clientY - rect.top) * scale];
      ctx.lineCap = "round";
      ctx.strokeStyle = "black";
      return;
    }
    ctx.beginPath();
    const dest = [(event.clientX - rect.left) * scale, (event.clientY - rect.top) * scale];
    ctx.lineWidth = getStroke(dest);
    ctx.moveTo(start[0], start[1]);
    start = dest;
    ctx.lineTo(start[0], start[1]);
    ctx.stroke();
  }

  function getStroke(dest) {
    const x = dest[0] - start[0];
    if (x) {
      const y = dest[1] - start[1];
      stroke -= y / (x * 4);
      if (stroke < 3 * scale) stroke = 3 * scale;
      if (stroke > 6 * scale) stroke = 6 * scale;
    }
    return stroke;
  }

  async function upload(event) {
    event.preventDefault();
    event.stopPropagation();
    setErr();
    try {
      const file = event.target.files[0];
      if (!file) throw { reason: "none", message: "No file found!" };
      if (!["image/png", "image/jpeg"].includes(file?.type)) throw { reason: "incorrect", message: "Incorrect file type" };
      var reader = new FileReader();
      reader.onload = event => {
        const image = new Image(imgSize.width * imgScale, imgSize.height * imgScale);
        image.imageRendering = "crisp-edges";
        image.src = event.target.result;
        image.onload = () => imgRef.current.getContext("2d").drawImage(image, 0, 0,
          imgSize.width * imgScale, imgSize.height * imgScale);
      }
      reader.readAsDataURL(file);
    } catch (err) {
      if (err.response) {
        console.error(err.response?.data);
        setErr(err.response?.data);
      } else {
        console.error(err);
        setErr(err);
      }
    }
  }

  function generate(font) {
    let ctx = imgRef.current.getContext("2d");
    let fontsize = 200;
    let margin = type === "initial" ? 30 : 10;
    ctx.clearRect(0, 0, imgRef.current.width, imgRef.current.height);
    ctx.fillStyle = "black";
    do {
      ctx.font = `${fontsize}px ${font}`;
      fontsize -= 3;
    } while (ctx.measureText(genContent).width >= imgRef.current.width - margin);
    ctx.fillText(genContent, margin, (imgRef.current.height + fontsize) / 2);
  }

  return (
    <Col className="text-center">
      <h5 className="color-blue">{title.toUpperCase()}</h5>
      <canvas ref={imgRef} {...drawParams} src={user[type]} className={ isMobile ? 'w-100 opacity-75 rounded border border-dark dotted-dashed' : 'opacity-75 rounded border border-dark dotted-dashed'}
        onMouseDown={() => setDrawParams({ onMouseMove: draw })}
        onMouseLeave={() => setDrawParams({})} onMouseUp={() => setDrawParams({})}
        width={imgSize.width * imgScale} height={imgSize.height * imgScale}
        style={{width: imgSize.width * imgScale / scale, height: imgSize.height * imgScale /scale}} />
      <Stack direction="horizontal" className="d-block">
        { !isMobile ? <p className="signature-passage">Click, hold and draw on the blank space to create your unique {title.toLowerCase()}.</p> : ""}
        <div className="d-flex flex-wrap align-content-center align-items-center justify-content-center">
          <span className="mr-1 mb-2">
            <Form.Group className="ms-2 d-inline mb-2">
              <Form.Control id={`u${type}`} className="uploadHidden" type="file" accept="image/png,image/jpeg"
                onChange={event => upload(event)} />
                <Form.Label htmlFor={`u${type}`} className="btn btn-light mb-0 bg-light-blue fs-12">
                <FontAwesomeIcon icon={faUpload} /> UPLOAD
              </Form.Label>
            </Form.Group>
          </span>
          <DropdownButton variant="secondary" className="mr-1 d-flex-child mb-2"
            title={<><span className="material-icons-outlined fs-12 m-a">draw</span> <span className="sign-text-span fs-12">GENERATE</span></>}>
            <Form.Group className="px-2">
              <Form.Control plaintext placeholder="Generate content" value={genContent}
                onChange={event => setGenContent(event.target.value)} />
            </Form.Group>
            <div style={{maxHeight: "20rem"}}>
              {signFonts.map(font => <Dropdown.Item key={font} style={{fontFamily: font, fontSize: 12}} onClick={() => generate(font)}>
                {genContent}
              </Dropdown.Item>)}
            </div>
          </DropdownButton>
          <Button variant="secondary" className="fs- mb-2" onClick={() =>
            imgRef.current.getContext("2d").clearRect(0, 0, imgRef.current.width, imgRef.current.height)}>
            <FontAwesomeIcon icon={faBan} /> CLEAR
          </Button>
        </div>
      </Stack>
    </Col>
  );
});
