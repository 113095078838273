import { useOutletContext, useSearchParams } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { useAuth } from "../AuthContext";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default function SendOnce() {
  const { doc, setDoc } = useOutletContext();
  const [user] = useAuth();
  const [searchParams] = useSearchParams();

  function selfcheck(event, signer) {
    if (event.target.checked) {
      setDoc({...doc, signers: doc.signers.map(s => s === signer
        ? {...signer,
            name: `${user.firstname} ${user.lastname}`,
            email: user.email
        }
        : s.email === user.email ? {...s, name: "", email: ""} : s)});
    } else {
      setDoc({...doc, signers: doc.signers.map(s => s === signer
        ? {...signer, name: "", email: ""} : s)});
    }
  }

  function renderAddFunction(){
    if(searchParams.get("receive") == "true")
      return <Stack>
        <div className="row py-3">
          <Button variant="primary" className="button-general-styling w-a ml-auto" onClick={() => setDoc({
            ...doc, signers: [...doc.signers, {
              label: `Receiver ${doc?.signers.length + 1}`,
              name: "",
              email: ""
              }]
            })} >
            <FontAwesomeIcon icon={faPlus} /> ADD
          </Button>
        </div>
      </Stack>;
  }

  function changeState(signer){
    if(searchParams.get("receive") == "true"){
      signer.state = 2;
    }
  }

  function stateLabelName(signer){
    var paramaters = searchParams.get("state");
    if(paramaters == 0)
      return signer.name;
    else
      return signer.label
  }

  return <>
    {doc?.signers.map((signer, index) => 
    <Stack key={signer.signerid} direction="horizontal" className="mb-2" gap={2}>{changeState(signer)}
      <Form.Check name="self" type="checkbox" aria-label="self" checked={signer.email === user.email}
        onChange={event => selfcheck(event, signer)} />
      <FloatingLabel label={`${stateLabelName(signer)}'s name`} className="flex-fill">
        <Form.Control required type="name" placeholder="Name" className="bg-light" value={signer.name} autoFocus
          onChange={event => setDoc({...doc, signers: doc.signers.map(s => s === signer
            ? {...signer, name: event.target.value} : s)})} />
      </FloatingLabel>         
      <FloatingLabel label={`${stateLabelName(signer)}'s email`} className="flex-fill">
        <Form.Control required type="email" placeholder="Email" value={signer.email} className="bg-light" 
          onChange={event => setDoc({...doc, signers: doc.signers.map(s => s === signer
            ? {...signer, email: event.target.value} : s)})} />
      </FloatingLabel>
    </Stack>)}
    {renderAddFunction()}
  </>;
}
