import React, { createContext, useContext, useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import PropTypes from "prop-types";

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success", messageDetail: "" });

  const showSnackbar = (message, severity = "success", messageDetail = "") => {
    setSnackbar({ open: true, message, severity, messageDetail });
  };

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  SnackbarProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <Snackbar
        key={snackbar.message}
        open={snackbar.open}
        autoHideDuration={10000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%", color: "white", fontSize: "12pt" }}
        >
          <span style={{ fontWeight: "normal" }}>{snackbar.message}</span>
          <span style={{ fontWeight: "normal" }}>{snackbar.messageDetail}</span>
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
