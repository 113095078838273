import { useEffect, useState } from "react";
import { Link, Outlet, useOutletContext, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Stack from "react-bootstrap/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../utils";
import { useAuth } from "../AuthContext";
import CollapseList from "./CollapseList";

export default function Outbox() {
  const { docid } = useParams();
  const [user] = useAuth();
  const { docs, setDocs, err } = useOutletContext();
  const [newDocs, setNewDocs] = useState([]);
  const [completeDocs, setCompleteDocs] = useState([]);
  const [boxWidth, setBoxWidth] = useState(docid ? 2 : 1);

  useEffect(() => {
    if (boxWidth) setBoxWidth(docid ? 2 : 1)
  }, [docid]);

  useEffect(() => {
    setNewDocs(docs.filter(doc => doc.ownerid === user.userid && doc.state === 2
      && doc.signers.find(signer => signer.userid === user.userid)?.state === 1));
    setCompleteDocs(docs.filter(doc => doc.ownerid === user.userid && doc.state === 2
      && doc.signers.find(signer => signer.userid === user.userid)?.state === 2));
  }, [docs]);

  return <>
    <ListGroup variant="flush" className="bg-white border-end py-2 overflow-scroll list-group list-group-width">
      <ListGroup.Item className="d-flex bg-light">
      <div className="sidecard-header1"><FontAwesomeIcon icon={faPaperPlane} size="lg" className="mx-2"/>DOCUMENTS </div><div className="sidecard-header2 mx-1"> SENT</div>
        <Button variant="light" size="sm" className="ms-auto close-btn" as={Link} to="/home"> 
          <FontAwesomeIcon icon={faXmark}/>
        </Button>
      </ListGroup.Item>
      {err?.message && <ListGroup.Item>{err?.message}</ListGroup.Item>}
      <CollapseList title="DOCUMENTS SENT">
        {completeDocs.map(doc => <OutboxItem key={doc.docid} doc={doc} complete={true} summary={boxWidth === 0} />)}
      </CollapseList>
    </ListGroup>
    <Outlet context={{ setDocs }} />
  </>;
}

export function OutboxItem({ doc, complete, summary }) {
  const { docid } = useParams();
  const [user] = useAuth();

  return (
    <ListGroup.Item key={doc.docid} as={Link} to={`${doc.docid}`} action active={parseInt(docid) === doc.docid}
      className={complete && "text-muted"}>
      <Stack className="overflow-hidden">
        <span className="d-flex doc-name-text">
          {doc.name}
          {!summary && <small className="ms-auto text-muted">{formatDate(doc.timesent)}</small>}
        </span>
        <span className="d-flex doc-client-text">
          {doc.signers.map(signer => signer.name).join(", ")}
          {complete && <FontAwesomeIcon icon={faCheck} className="ms-auto" />}
        </span>
        {[1, 2].includes(doc.signers.find(signer => signer.userid === user.userid)?.state)}
      </Stack>
    </ListGroup.Item>
  );
}
