import { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import { formatDate } from "../utils";
import { useAuth } from "../AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function TemplateList() {
  const [user] = useAuth();
  const { docs, setTitle } = useOutletContext();
  const [thisDocs, setThisDocs] = useState([]);
  const [docid, setDocid] = useState();

  useEffect(() => setTitle("SELECT TEMPLATE"), []);

  useEffect(() => {
    setThisDocs(docs.filter(doc => doc.ownerid === user.userid && doc.state === 1));
  }, [docs]);

  return <>
    <Modal.Body>
      <ListGroup variant="flush" style={{height: "15rem"}} className="overflow-scroll">
        {thisDocs.map(doc => <ListGroup.Item key={doc.docid} action className="d-flex" active={docid === doc.docid}
          onClick={() => setDocid(doc.docid)}>
          {doc.name}
          <small className="ms-auto text-muted">{formatDate(doc.timecreated)}</small>
        </ListGroup.Item>)}
      </ListGroup>
    </Modal.Body>
    <Modal.Footer className="bg-light">
      <Button as={Link} to="/home" variant="secondary" className="mr-auto"><FontAwesomeIcon icon={solid("xmark")} /> Cancel</Button>
      {docid && <Button as={Link} to={`/home/templates/${docid}/send/template`} variant="dark" className="orange mr-auto">Continue</Button>}
      <Button as={Link} to="../create?template=true&state=1" variant="light">Upload new</Button>
    </Modal.Footer>
  </>;
}
