import { useState } from "react";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

export default function New() {
  const navigate = useNavigate();
  const { docs } = useOutletContext();
  let [title, setTitle] = useState("");
  let titleFirstWord = "";

  function seperateTitle() {
    titleFirstWord = title.split(" ")[0];
    title = title.substr(title.indexOf(" ") + 1);
  }

  return (
    <Modal show={true} size="md" onHide={() => navigate("..")} backdrop="static">
      <Modal.Header className="row text-white bg-black m-0 border-none">
          <h1 className="card-header-text my-2">{seperateTitle()}<span className="ml-1 text-truncate"><span className="title-first-word ">{titleFirstWord}</span>&nbsp;{title}</span> </h1>
      </Modal.Header>
      <Outlet context={{ docs, setTitle }} />
    </Modal>
  );
}
