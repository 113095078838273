
import React, { useState } from "react";

export const UserContext = React.createContext("");

export function useAuth() {
  return React.useContext(UserContext);
}

export default function AuthContext({ children }) {
  const [user, setUser] = useState();

  
  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  );
}
