import React from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

export default function FinishFields() {
  return (
    <Modal show={true} backdrop="static">
      <Modal.Header className="bg-light">
        <Modal.Title>Complete Required Sections</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please ensure that you have signed and completed all required<br />sections before clicking the Sign button to complete the process.</p>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <Button variant="light" className="me-auto" as={Link} to="..">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
