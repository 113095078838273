import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function FinishReject() {
  const navigate = useNavigate();
  const { doc } = useOutletContext();

  return (
    <Modal show={true} onHide={() => navigate("/home")} backdrop="static">
      <Modal.Header className="bg-light">
        <Modal.Title>Document rejected</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><b>{doc?.name}</b> rejected successfully. The sender will be notified.</p>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <Button as={Link} to="/home/inbox" variant="light" className="me-auto">Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
