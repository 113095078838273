import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useAuth } from "../AuthContext";
import Form from "react-bootstrap/Form";

export default function FinishSend() {
  const navigate = useNavigate();
  const [doc, setDoc] = useState();
  const [err, setErr] = useState();
  const [user, setUser] = useAuth();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");

  useEffect(() => {
    (async () => {
      try {
        setFirstname(user.firstname ?? "");
        setLastname(user.lastname ?? "");
      } catch (err) {
        console.error(err);
        setErr(err.response?.data);
      }
    })();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/partialUser/signUp`, {
        userid: user.userid,
        firstname: firstname,
        lastname: lastname
      });
      setUser(res.data);
      navigate("..");
    } catch (err) {
      if (err.response) {
        console.error(err.response?.data);
        setErr(err.response?.data);
      } else {
        console.error(err);
        setErr(err);
      }
    }
  }

  return (
    <Modal show={true} onHide={() => navigate("..")} backdrop="static">
      <Modal.Header className="bg-light">
        <Modal.Title>Please check your First and Last name</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="SetUserNames">
          <Form.Control className="pt-3 pb-3 mt-4 mb-3 textbox-square-border" type="text" name="firstname" required placeholder="First name"
            value={firstname} onChange={event => setFirstname(event.target.value)} />
          <Form.Control className="pt-3 pb-3 mt-4 mb-3 textbox-square-border" type="text" name="lastname" required placeholder="Last name"
            value={lastname} onChange={event => setLastname(event.target.value)} />
          {err?.reason === "verified" && <Link to="/auth/login"><Button variant="secondary" className="float-right button-general-styling my-1">LOGIN</Button></Link>}
        </Form>
        {err?.message && <p className="text-danger">{err?.message}</p>}
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <Button as={Link} to=".." variant="light" className="me-auto">Close</Button>
        <Button as={Link} to=".." variant="light" className="mw-auto" onClick={handleSubmit}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}
