import { useEffect, useState } from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import { useAuth } from "../AuthContext";

export default function Inbox() {
  const { docid } = useParams();
  const [user] = useAuth();
  const { docs, setDocs, err } = useOutletContext();
  const [newDocs, setNewDocs] = useState([]);
  const [boxWidth, setBoxWidth] = useState(docid ? 2 : 1);

  useEffect(() => {
    if (boxWidth) setBoxWidth(docid ? 2 : 1)
  }, [docid]);

  useEffect(() => {
    setNewDocs(docs.filter(doc => doc.state === 2
      && doc.signers.find(signer => signer.userid === user.userid)?.state === 1));
  }, [docs]);

  return <>    
    <Outlet context={{ docs, setDocs }} />
  </>;
}