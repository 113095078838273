import axios from "axios";
import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useOutletContext } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import UploadBox from "./UploadBox";

export default function Validate() {
  const navigate = useNavigate();
  const { docs, setTitle } = useOutletContext();
  const [response, setResponse] = useState({});
  const [err, setErr] = useState();

  useEffect(() => setTitle("Validate document"), []);

  async function validateCallback(event, file) {
    event.preventDefault();
    event.stopPropagation();
    try {
      if (!file) throw { reason: "none", message: "No file found!" };
      if (file?.type !== "application/pdf") throw { reason: "incorrect", message: "File should be in .pdf format" };
      let body = new FormData();
      body.append("file", file);
      const res = (await axios.post(`${process.env.REACT_APP_API_URL}/docs/validate`, body, {
        headers: { "Content-Type": "multipart/form-data" }
      })).data;
      console.log(res);
      setResponse(res);
    } catch (err) {
      console.error(err.response?.data ?? err);
      setErr(err.response?.data ?? err);
    }
  }

  return <>
    <Modal.Body as={Stack} gap={4}>
      <UploadBox callback={validateCallback} />
      {response?.reason && <div className="bg-light rounded border p-2">
        <h5>{response?.reason}</h5>
        {response.reason === "Valid Signature"
          ? <Certificate cert={response?.message.cert} />
          : <p>{response?.message}</p>}
      </div>}
    </Modal.Body>
    <Modal.Footer className="bg-light">
      <Button variant="light" as={Link} to="/home">Cancel</Button>
    </Modal.Footer>
  </>;
}

function Certificate({ cert }) {
  if (!cert) return <p>Loading...</p>;
  return <>
    <p>Document signature verified successfully!</p>
    <table>
      <tr>
        <td className="pe-2">Certificate issuer:</td>
        <td>{cert.issuer.commonName}, {cert.issuer.organizationName}, {cert.issuer.countryName}</td>
      </tr>
      <tr>
        <td className="pe-2">Validity:</td>
        <td>{new Date(cert.validity.start).toDateString()} to {new Date(cert.validity.end).toDateString()}.</td>
      </tr>
      <tr>
        <td className="pe-2">Serial number:</td>
        <td>{cert.serial}</td>
      </tr>
    </table>
  </>;
}
