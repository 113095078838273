import axios from "axios";
import { useEffect, useState } from "react";
import { createSearchParams, Navigate, Routes, Route, useNavigate, useSearchParams } from "react-router-dom";
import * as pdfjs from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useAuth } from "./AuthContext";
import { isMobile } from 'react-device-detect';
import Account from "./home/Account";
import Archives from "./home/Archives";
import Create from "./new/Create";
import CreateDoc from "./new/CreateDoc";
import Drafts from "./home/Drafts";
import Draw from "./doc/Draw";
import Edit from "./doc/Edit";
import Home from "./home/Home";
import SignerHome from "./home/SignerHome";
import Inbox from "./home/Inbox";
import FinishSend from "./doc/FinishSend";
import FinishSign from "./home/FinishSign";
import FinishReject from "./home/FinishReject";
import FinishRemind from "./home/FinishRemind";
import Forgot from "./auth/Forgot";
import ForgotReset from "./auth/ForgotReset";
import ForgotVerify from "./auth/ForgotVerify";
import Login from "./auth/Login";
import New from "./new/New";
import Outbox from "./home/Outbox";
import Complete from "./home/Complete"
import AwaitingSigniture from "./home/AwaitingSigniture"
import Package from "./doc/Package";
import Profile from "./home/Profile";
import Register from "./auth/Register";
import Reject from "./doc/Reject";
import UpdateName from "./doc/UpdateName";
import Remind from "./doc/Remind";
import Search from "./home/Search";
import Send from "./doc/Send";
import SendOnce from "./doc/SendOnce";
import SendTemplate from "./doc/SendTemplate";
import Settings from "./home/Settings";
import Signers from "./doc/Signers";
import SignerInbox from "./home/SignerInbox";
import Signup from "./auth/Signup";
import Templates from "./home/Templates";
import TemplateList from "./new/TemplateList";
import Validate from "./new/Validate";
import ValidateDoc from "./new/ValidateDoc";
import Verify from "./auth/Verify";
import View from "./doc/View";
import FinishPackage from "./home/FinishPackage";
import FinishFields from "./home/FinishFields";
import Rejected from "./home/Rejected";
import RegisterAfterSign from "./auth/RegisterAterSign";

export default function App() {
  const [user, setUser] = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    (async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/users`);
        setUser(res.data);
      } catch (err) {
        console.error(err.response?.data);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return <p>Loading</p>
  }
  return (
    <Routes>
      <Route path="/auth">
        <Route path="signup">
          <Route path="email" element={<Signup />} />
          <Route path="verify" element={<Verify />} />
          <Route path="register" element={<Register />} />
          <Route path="register-after-sign" element={<RegisterAfterSign />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="forgot">
          <Route path="email" element={<Forgot />} />
          <Route path="verify" element={<ForgotVerify />} />
          <Route path="reset" element={<ForgotReset />} />
        </Route>
      </Route>
      <Route path="/edit/:docid" element={<ProtectedRoute><Edit /></ProtectedRoute>}>
        <Route path="send" element={<Send />}>
          <Route path="once" element={<SendOnce />} />
        </Route>
        <Route path="draw" element={<Draw />} />
        <Route path="finish" element={<FinishSign />} />
      </Route>
      <Route path="/package/:docid" element={<ProtectedRoute><Package /></ProtectedRoute>}>
        <Route path="signers" element={<Signers />} />
        <Route path="send" element={<Send />}>
          <Route path="once" element={<SendOnce />} />
          <Route path="template" element={<SendTemplate />} />
        </Route>
        <Route path="finish" element={<FinishPackage />} />
      </Route>
      <Route path="signer-home" element={<SignerHome />}>
        <Route path="inbox" element={<SignerInbox />}>
          <Route path=":docid" element={<View />}>
            <Route path="update-name" element={<UpdateName />} />
            <Route path="fields" element={<FinishFields />} />
            <Route path="draw" element={<Draw />} />
            <Route path="finish" element={<FinishSign />} />
            <Route path="reject/finish" element={<FinishReject />} />
            <Route path="reject" element={<Reject />} />
            <Route path="remind/finish" element={<FinishRemind />} />
            <Route path="remind" element={<Remind />} />
          </Route>
        </Route>
        <Route path="new" element={<New />}>
          <Route path="create" element={<Create />}>
            <Route path=":docid" element={<CreateDoc />} />
          </Route>
          <Route path="template" element={<TemplateList />}>
            <Route path=":docid" element={<CreateDoc />} />
          </Route>
          <Route path="validate" element={<Validate />}>
            <Route path=":docid" element={<ValidateDoc />} />
          </Route>
        </Route>
        <Route path="outbox" element={<Outbox />}>
          <Route path=":docid" element={<View />}>
            <Route path="draw" element={<Draw />} />
            <Route path="finish" element={<FinishSign />} />
            <Route path="reject/finish" element={<FinishReject />} />
            <Route path="reject" element={<Reject />} />
            <Route path="remind/finish" element={<FinishRemind />} />
            <Route path="remind" element={<Remind />} />
          </Route>
        </Route>
        <Route path="complete" element={<Complete />}>
          <Route path=":docid" element={<View />}>
            <Route path="draw" element={<Draw />} />
            <Route path="finish" element={<FinishSign />} />
            <Route path="reject/finish" element={<FinishReject />} />
            <Route path="reject" element={<Reject />} />
            <Route path="remind/finish" element={<FinishRemind />} />
            <Route path="remind" element={<Remind />} />
          </Route>
        </Route>
        <Route path="awaiting-signiture" element={<AwaitingSigniture />}>
          <Route path=":docid" element={<View />}>
            <Route path="draw" element={<Draw />} />
            <Route path="finish" element={<FinishSign />} /> 
            <Route path="reject/finish" element={<FinishReject />} />
            <Route path="reject" element={<Reject />} />
            <Route path="remind/finish" element={<FinishRemind />} />
            <Route path="remind" element={<Remind />} />
          </Route>
        </Route>
        <Route path="drafts" element={<Drafts />}>
          <Route path=":docid" element={<View />}>
            <Route path="send" element={<Send />}>
              <Route path="once" element={<SendOnce />} />
            </Route>
          </Route>
        </Route>
        <Route path="templates" element={<Templates />}>
          <Route path=":docid" element={<View />}>
            <Route path="send" element={<Send />}>
              <Route path="template" element={<SendTemplate />} />
            </Route>
          </Route>
        </Route>
        <Route path="archives" element={<Archives />}>
          <Route path=":docid" element={<View />} />
        </Route>
        <Route path="search" element={<Search />}>
          <Route path=":docid" element={<View />}>
            <Route path="draw" element={<Draw />} />
            <Route path="finish" element={<FinishSign />} />
            <Route path="send" element={<Send />}>
              <Route path="once" element={<SendOnce />} />
              <Route path="template" element={<SendTemplate />} />
            </Route>
            <Route path="reject" element={<Reject />} />
          </Route>
        </Route>
        <Route path="send/:docid/finish" element={<FinishSend />} />
        <Route path="account" element={<Account />}>
          <Route path="profile" element={<Profile />} />
          <Route path="settings" element={<Settings />} />
        </Route>
      </Route>
      <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>}>
        <Route path="new" element={<New />}>
          <Route path="create" element={<Create />}>
            <Route path=":docid" element={<CreateDoc />} />
          </Route>
          <Route path="template" element={<TemplateList />}>
            <Route path=":docid" element={<CreateDoc />} />
          </Route>
          <Route path="validate" element={<Validate />}>
            <Route path=":docid" element={<ValidateDoc />} />
          </Route>
        </Route>
        <Route path="inbox" element={<Inbox />}>
          <Route path=":docid" element={<View />}>
            <Route path="fields" element={<FinishFields />} />
            <Route path="draw" element={<Draw />} />
            <Route path="finish" element={<FinishSign />} />
            <Route path="reject/finish" element={<FinishReject />} />
            <Route path="reject" element={<Reject />} />
            <Route path="remind/finish" element={<FinishRemind />} />
            <Route path="remind" element={<Remind />} />
          </Route>
        </Route>
        <Route path="outbox" element={<Outbox />}>
          <Route path=":docid" element={<View />}>
            <Route path="draw" element={<Draw />} />
            <Route path="finish" element={<FinishSign />} />
            <Route path="reject/finish" element={<FinishReject />} />
            <Route path="reject" element={<Reject />} />
            <Route path="remind/finish" element={<FinishRemind />} />
            <Route path="remind" element={<Remind />} />
          </Route>
        </Route>
        <Route path="complete" element={<Complete />}>
          <Route path=":docid" element={<View />}>
            <Route path="draw" element={<Draw />} />
            <Route path="finish" element={<FinishSign />} />
            <Route path="reject/finish" element={<FinishReject />} />
            <Route path="reject" element={<Reject />} />
            <Route path="remind/finish" element={<FinishRemind />} />
            <Route path="remind" element={<Remind />} />
          </Route>
        </Route>
        <Route path="awaiting-signiture" element={<AwaitingSigniture />}>
          <Route path=":docid" element={<View />}>
            <Route path="draw" element={<Draw />} />
            <Route path="finish" element={<FinishSign />} /> 
            <Route path="reject/finish" element={<FinishReject />} />
            <Route path="reject" element={<Reject />} />
            <Route path="remind/finish" element={<FinishRemind />} />
            <Route path="remind" element={<Remind />} />
          </Route>
        </Route>
        <Route path="rejected" element={<Rejected />}>
          <Route path=":docid" element={<View />}>
            <Route path="send" element={<Send />}>
              <Route path="once" element={<SendTemplate />} />
            </Route>
          </Route>
        </Route>
        <Route path="drafts" element={<Drafts />}>
          <Route path=":docid" element={<View />}>
            <Route path="send" element={<Send />}>
              <Route path="once" element={<SendOnce />} />
            </Route>
          </Route>
        </Route>
        <Route path="templates" element={<Templates />}>
          <Route path=":docid" element={<View />}>
            <Route path="send" element={<Send />}>
              <Route path="template" element={<SendTemplate />} />
            </Route>
          </Route>
        </Route>
        <Route path="archives" element={<Archives />}>
          <Route path=":docid" element={<View />} />
        </Route>
        <Route path="search" element={<Search />}>
          <Route path=":docid" element={<View />}>
            <Route path="draw" element={<Draw />} />
            <Route path="finish" element={<FinishSign />} />
            <Route path="send" element={<Send />}>
              <Route path="once" element={<SendOnce />} />
              <Route path="template" element={<SendTemplate />} />
            </Route>
            <Route path="reject" element={<Reject />} />
          </Route>
        </Route>
        <Route path="send/:docid/finish" element={<FinishSend />} />
        <Route path="account" element={<Account />}>
          <Route path="profile" element={<Profile />} />
          <Route path="settings" element={<Settings />} />
        </Route>
      </Route>
      <Route path="/email" element={<Email />} />
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="*" element={<p>Not found</p>} />
    </Routes>
  );
}

function ProtectedRoute({ children }) {
  const [user] = useAuth();

  if (!user) {
    return <Navigate to={{
      pathname: "/auth/login",
      search: createSearchParams({
        redirect: window.location.pathname,
      }).toString()
    }} />;
  } else if (user.state == 0) {
    return <Navigate to={{
      pathname: "/auth/signup/register",
      search: createSearchParams({
        email: user.email,
        redirect: window.location.pathname,
      }).toString()
    }} />
  } else if (user.company == null) {
    return <Navigate to={{
      pathname: "/auth/signup/register-after-sign",
      search: createSearchParams({
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        redirect: window.location.pathname,
      }).toString()
    }} />
  }

  return children;
}

function Email() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [err, setErr] = useState();
  const [user, setUser] = useAuth();

  useEffect(() => {
    (async () => {
      try {
        const res = (await axios.post(`${process.env.REACT_APP_API_URL}/auth/email`, {
          email: searchParams.get("email"),
          redirect: searchParams.get("redirect")
        })).data;
        setUser(res.user);
        navigate(res.url);
      } catch (err) {
        console.error(err);
        setErr(err.response?.data);
      }
    })();
  }, []);

  return <p>{err?.message}</p>;
}
