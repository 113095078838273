import { useState } from "react";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAuth } from "../AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import logo from "../files/images/logo_tagline.png";

export default function Login() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [user, setUser] = useAuth();
  const [email, setEmail] = useState(searchParams.get("email") ?? "");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
        email: email,
        password: password
      });
      setUser(res.data);
      navigate(searchParams.get("redirect") ?? "/home");
    } catch (err) {
      console.error(err.response?.data);
      setErr(err.response?.data);
    }
  }

  return (
    <div className="container">
      <div className="col-lg-5 col-md-7 col-10 mx-auto">
        <div className="row">
          <img src={logo} className="logo-image my-5"></img>
        </div>
        <div className="row">
          <div className="row text-white bg-black">
            <h1 className="card-header-text my-3">WELCOME</h1>
          </div>
          <div className="row bg-white">
            <Form id="LoginForm" onSubmit={handleSubmit}>
              <Form.Control className="pt-3 pb-3 mt-4 mb-3 textbox-square-border" type="email" name="email" required placeholder="Email address"
                value={email} onChange={event => setEmail(event.target.value)} />
              <Form.Control className="pt-3 pb-3 mb-3 textbox-square-border" type="password" name="password" required placeholder="Password"
                value={password} onChange={event => setPassword(event.target.value)} />
              <div className="row">
                {err?.message && <Alert variant="danger">{err?.message}</Alert>}
                <Link to="../forgot/email" className="forgot-text" tabIndex={-1}>Forgot Password</Link>
              </div>
              <div className="row pb-3 pt-3">
                <div className="navigation-container">
                  <Button className="button-general-styling py-2 my-1 btn-auth" variant="secondary" as={Link} to="../signup/email">
                    <span className="material-icons-outlined auth-icons">note_alt</span>
                    <span className="card-button">SIGN UP</span>
                  </Button>
                  <Button className="button-general-styling py-2 my-1 btn-auth" variant="primary" type="submit" form="LoginForm">
                    <span className="material-icons-outlined auth-icons">login</span>
                    <span className="card-button">LOGIN</span>
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="row slogan-text">
          <p>EVOLVE</p>
        </div>
      </div>
    </div>
  );
}
