import axios from "axios";
import { useEffect, useState } from "react";
import { createSearchParams, Link, useNavigate, useSearchParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import logo from "../files/images/logo_tagline.png";


export default function Verify() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [code, setCode] = useState(searchParams.get("code") ?? "");
  const [info, setInfo] = useState("");
  const [err, setErr] = useState();

  useEffect(() => {
    if (searchParams.get("code")) handleSubmit();
  }, []);

  async function handleSubmit(event) {
    event?.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/signup/verify`, {
        email: searchParams.get("email"),
        code: code
      });
      navigate({
        pathname: "../register",
        search: createSearchParams({
          email: searchParams.get("email"),
          ...(searchParams.get("redirect") ? { redirect: searchParams.get("redirect") } : {}),
        }).toString()
      });
    } catch (err) {
      console.error(err);
      setErr(err.response?.data);
    }
  }

  async function handleRenew(event) {
    event.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/signup/verify/renew`, {
        email: searchParams.get("email")
      });
      setCode("");
      setInfo("Success! New verification code sent.");
      setErr("");
    } catch (err) {
      console.error(err.response?.data);
      setErr(err.response?.data);
    }
  }

  return (

    <div className="container">
      <div className="col-lg-5 col-md-7 col-10 mx-auto">
        <div className="row">
          <img src={logo} className="logo-image my-5"></img>
        </div>
        <div className="row">
          <div className="row text-white bg-black">
            <h1 className="card-header-text py-3">VERIFY EMAIL</h1>
          </div>
          <div className="row bg-white">
            <Form id="VerifyForm" onSubmit={handleSubmit}>
                <Form.Control className="pt-3 pb-3 mt-4 mb-3 textbox-square-border" type="email" name="email" required placeholder="Email"
                  value={searchParams.get("email")} disabled={true} />
              <p>Success! A 6-digit verification code has been sent to your email.</p>
                <Form.Control className="pt-3 pb-3 mt-4 mb-3 textbox-square-border" type="text" name="code" required placeholder="Verification code"
                  value={code} onChange={event => setCode(event.target.value.toUpperCase())} autoComplete="off" />
              <div className="row pb-3">
                <div>
                  <Button variant="primary" className="float-right button-general-styling btn-auth" type="submit" form="VerifyForm">Sign Up</Button>
                  {err?.reason === "expired" && <Button variant="secondary" className="float-right button-general-styling btn-auth" onClick={handleRenew}>NEW VERIFICATION CODE</Button>}
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="row slogan-text">
          <p>EVOLVE</p>
        </div>
      </div>
    </div>
  );
}
