import axios from "axios";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Stack from "react-bootstrap/Stack";
import { isImg, isInputtype, inputs, drawSizes, statelabel } from "../utils";
import useWindowDimensions from "../utils";
import { isSafari } from 'react-device-detect'

export default function EditInput({ doc, setDoc, docRef, input, currentInput, setCurrentInput, prevInput,
  setResizeInput, setCurrentSigner, setFontsize, setRequired, scale = 1 }) {
  const [start, setStart] = useState([0, 0, 0]);
  const [signer, setSigner] = useState({});
  const [dragging, setDragging] = useState(false);
  const [changed, setChanged] = useState(false);
  const [popPlace, setPopPlace] = useState("top");
  const [signerDrop, setSignerDrop] = useState(false);
  const [typeDrop, setTypeDrop] = useState(false);
  const [inputLabel, setInputLabel] = useState("");
  const inputRef = useRef();
  const [searchParams] = useSearchParams();
  const { width } = useWindowDimensions();
  
  const [isMobile, setIsMobile] = useState(width < 1280);

  useEffect(() => {
    if(width < 1280 ){
      setIsMobile(true);
    } 
    if(width > 1279 ){
      setIsMobile(false);
    } 
  }, [width]);

  useEffect(() => {
    let signer = doc.signers.find(s => s.signerid === input.signerid);
    setSigner(signer);
  }, [input.signerid, doc.signers])

  useEffect(() => setInputLabel(`${statelabel(stateLabelName(), signer)}'s ${inputs[input.type]}`),
    [signer, input.type]);

  useEffect(() => {
    if (prevInput?.inputid === input.inputid && changed) {
      update();
      setChanged(false);
    }
  }, [currentInput]);

  function stateLabelName(){
    return parseInt(searchParams.get("state"));
  }

  function update() {
    if (input.page == null || input.x < 0 || input.x + input.width > inputRef.current.parentElement.clientWidth
      || input.y < 0 || input.y + input.height > inputRef.current.parentElement.clientHeight) {
      axios.delete(`${process.env.REACT_APP_API_URL}/inputs/${input.inputid}`);
      setDoc({...doc, inputs: doc.inputs.filter(inpt => inpt !== input)});
    } else {
      axios.put(`${process.env.REACT_APP_API_URL}/inputs/`, input);
    }
  }

  function dragstart(event) {
    setCurrentInput(input);
    setStart([event.clientX, event.clientY, docRef.current.scrollTop]);
    setDragging(true);
  }

  function dragend(event) {
    input.x = input.x * scale + event.clientX - start[0];
    input.y = input.y * scale + event.clientY - start[1] + docRef.current.scrollTop - start[2];
    if (input.x < 0) input.x = scale;
    if (input.x + input.width * scale > inputRef.current.parentElement.clientWidth)
      input.x = inputRef.current.parentElement.clientWidth - input.width * scale;
    if (input.y < 0) input.y = scale;
    let heightOffset = isImg(input) ? input.height * scale : 2 * input.fontsize * scale;
    if (input.y + heightOffset > inputRef.current.parentElement.clientHeight)
      input.y = inputRef.current.parentElement.clientHeight - heightOffset;
    input.x /= scale;
    input.y /= scale;
    setDoc({...doc, inputs: doc.inputs.map(inpt => inpt === input ? input : inpt)});
    setDragging(false);
    setChanged(true);
    update();
  }

  function resizestart(event) {
    input.start = {};
    input.start.x = input.x;
    input.start.y = input.y;
    input.start.clientX = event.clientX;
    input.start.clientY = event.clientY;
    input.start.width = input.width;
    input.start.height = input.height;
    setResizeInput(input);
  }

  function menuclick(event) {
    event.preventDefault();
    event.stopPropagation();
    if (currentInput?.inputid === input.inputid) {
      setCurrentInput(null);
    } else {
      if (event.clientY < 300 && popPlace === "top") setPopPlace("bottom");
      if (event.clientY >= 300 && popPlace === "bottom") setPopPlace("top");
      setCurrentInput(input);
    }
  }

  function setLabel(event) {
    input.label = event.target.value;
    setDoc({...doc, inputs: doc.inputs.map(inpt => inpt === input ? input : inpt)})
    setChanged(true);
  }

  function changeRequired(event) {
    input.required = !input.required;
    setDoc({...doc, inputs: doc.inputs.map(inpt => inpt === input ? input : inpt)})
    setChanged(true);
    setRequired(input.required);
  }

  async function deleteInput() {
    try {
      axios.delete(`${process.env.REACT_APP_API_URL}/inputs/${input.inputid}`);
      setDoc({...doc, inputs: doc.inputs.filter(inpt => inpt !== input)});
      setCurrentInput(null);
    } catch (err) {
      console.error(err);
    }
  }

  function changeSigner(signer) {
    input.signerid = signer.signerid;
    setDoc({...doc, inputs: doc.inputs.map(inpt => inpt.inputid === input.inputid ? input : inpt)});
    setChanged(true);
    setCurrentSigner(signer);
  }

  function changeInputType(index) {
    input.type = index;
    input.width = drawSizes(input).width;
    input.height = drawSizes(input).height;
    setDoc({...doc, inputs: doc.inputs.map(inpt => inpt.inputid === input.inputid ? input : inpt)});
    setChanged(true);
  }

  function changeFontsize(event) {
    let fontsize = event.target.value;
    if (!isImg(input) && fontsize >= 6 && fontsize <= 30) {
      setDoc({...doc, inputs: doc.inputs.map(inpt => inpt === input
      ? {...input, fontsize: fontsize} : inpt)});
      setChanged(true);
      setFontsize(fontsize);
    }
  }

  function renderInput() {
    if (isImg(input)) {
      return <ImgEditInput ref={inputRef} input={input} inputLabel={inputLabel} setChanged={setChanged}
        scale={scale} dragstart={dragstart} dragend={dragend} menuclick={menuclick} />;
    }
    if (isInputtype(input, "checkbox")) {
      return <CheckboxEditInput ref={inputRef} input={input} inputLabel={inputLabel} setChanged={setChanged}
        scale={scale} dragstart={dragstart} dragend={dragend} menuclick={menuclick} />;
    }
    if (isInputtype(input, "paragraph")) {
      return <ParagraphEditInput ref={inputRef} input={input} inputLabel={inputLabel} setChanged={setChanged}
        scale={scale} dragstart={dragstart} dragend={dragend} menuclick={menuclick} />;
    }
    return <TextEditInput ref={inputRef} input={input} inputLabel={inputLabel} setChanged={setChanged}
      scale={scale} dragstart={dragstart} dragend={dragend} menuclick={menuclick} />;
  }

  return <>
  <OverlayTrigger show={!isMobile && !dragging && currentInput?.inputid === input.inputid} placement={"top"} overlay={
    <Popover className="bg-light" style={{"display": "inline-table"}}
      onClick={event => {
        event.stopPropagation();
        setSignerDrop(false);
        setTypeDrop(false);
      }}>
      <Popover.Header className="header-input d-flex align-items-center">
        <span className="vertical-align">{`${statelabel(stateLabelName(), signer)}'s ${inputs[input.type]}`}</span>
        <Button className="header-delete ml-auto" onClick={deleteInput} size="sm">
        <span className="material-icons-outlined font-size-input mt-1">delete_forever</span>
        </Button>
      </Popover.Header>
      <Popover.Body as={Stack} gap={2} className="p-2 body-input">
        <Stack direction="horizontal" gap={2}>
          <DropdownButton show={signerDrop} title={<div className="auth-icons"><span className="material-icons-outlined sidebar-icon">person</span><span className="package-text-span"> Change signer</span></div>} variant="outline-dark" size="sm"
            onToggle={(value, event) => {
              event.originalEvent.stopPropagation();
              setSignerDrop(!signerDrop);
              setTypeDrop(false);
            }} id="body-dropdown-inputs">
            {doc.signers.map((signer, index) => <Dropdown.Item key={index} onClick={() => changeSigner(signer)}>
              {statelabel(stateLabelName(), signer)}
            </Dropdown.Item>)}
          </DropdownButton>
          <DropdownButton show={typeDrop} title={<div className="auth-icons"><span className="material-icons-outlined sidebar-icon">edit</span><span className="package-text-span"> Change type</span></div>} variant="outline-dark" size="sm"
            onToggle={(value, event) => {
              event.originalEvent.stopPropagation();
              setTypeDrop(!typeDrop);
              setSignerDrop(false);
            }} id="body-dropdown-inputs">
            {inputs.map((inputType, index) => <Dropdown.Item key={index} onClick={() => changeInputType(index)}>
              {inputType}
            </Dropdown.Item>)}
          </DropdownButton>
          <Button variant="outline-dark" size="sm" className="body-inputs" onClick={changeRequired}>
            {input.required ? "Required" : "Optional"}
          </Button>
          {!isImg(input) && !isInputtype(input, "checkbox") &&
            <Form.Group className="body-inputs">
              <Form.Label className="mt-1 d-ib m-rl-5">Font size</Form.Label>
              <Form.Control type="number" min={6} max={30}
                plaintext style={{width: "3rem"}} className="d-ib"
                value={input.fontsize} onChange={changeFontsize} />
            </Form.Group>}
        </Stack>
        <Stack direction="horizontal" gap={2}>
          <Form.Control placeholder="Description" className="description-input" plaintext
            value={input.label ?? ""} onChange={setLabel} />
        </Stack>
      </Popover.Body>
    </Popover>}>
    {renderInput()}
  {/* Popover for mobile devices */}
  </OverlayTrigger>
    <OverlayTrigger show={isMobile && !dragging && currentInput?.inputid === input.inputid} placement={"top"} overlay={
      <Popover className="bg-light" style={{"display": "inline-table"}}
        onClick={event => {
          event.stopPropagation();
          setSignerDrop(false);
          setTypeDrop(false);
        }}>
        <Popover.Header className="header-input d-flex align-items-center">
          <span className="vertical-align">{`${statelabel(stateLabelName(), signer)}'s ${inputs[input.type]} mobile `}</span>
          <Button className="header-delete ml-auto" onClick={deleteInput} size="sm">
          <span className="material-icons-outlined font-size-input mt-1">delete_forever</span>
          </Button>
        </Popover.Header>
        <Popover.Body as={Stack} gap={2} className="p-2 body-input">
          <Stack direction="horizontal" gap={2}>
            <div>
            <DropdownButton style={{width: `60px !important`}} show={signerDrop} title={<div className="auth-icons"><span className="material-icons-outlined sidebar-icon">person</span><span className="package-text-span"> Change signer</span></div>} variant="outline-dark" size="sm"
              onToggle={(value, event) => {
                event.originalEvent.stopPropagation();
                setSignerDrop(!signerDrop);
                setTypeDrop(false);
              }} id={isMobile ? "body-dropdown-inputs-mobile" : "body-dropdown-inputs"}>
              {doc.signers.map((signer, index) => <Dropdown.Item key={index} onClick={() => changeSigner(signer)}>
                {statelabel(stateLabelName(), signer)}
              </Dropdown.Item>)}
            </DropdownButton>
            <DropdownButton style={{width: `60px !important`}} show={typeDrop} title={<div className="auth-icons"><span className="material-icons-outlined sidebar-icon">edit</span><span className="package-text-span"> Change type</span></div>} variant="outline-dark" size="sm"
              onToggle={(value, event) => {
                event.originalEvent.stopPropagation();
                setTypeDrop(!typeDrop);
                setSignerDrop(false);
              }} id={isMobile ? "body-dropdown-inputs-mobile" : "body-dropdown-inputs"}>
              {inputs.map((inputType, index) => <Dropdown.Item key={index} onClick={() => changeInputType(index)}>
                {inputType}
              </Dropdown.Item>)}
            </DropdownButton>
            </div>
            <div>
              <Button variant="outline-dark" size="sm" className={isMobile ? "body-inputs-mobile" : "body-inputs"} onClick={changeRequired}>
                {input.required ? "Required" : "Optional"}
              </Button>
              {!isImg(input) && !isInputtype(input, "checkbox") &&
                <Form.Group className={isMobile ? "body-inputs-mobile" : "body-inputs"}>
                  <Form.Label className="mt-1 d-ib m-rl-5">Font size</Form.Label>
                  <Form.Control type="number" min={6} max={30}
                    plaintext style={{width: "3rem"}} className="d-ib"
                    value={input.fontsize} onChange={changeFontsize} />
                </Form.Group>}
              </div>
          </Stack>
          <Stack direction="horizontal" gap={2}>
            <Form.Control placeholder="Description" className="description-input" plaintext
              value={input.label ?? ""} onChange={setLabel} />
          </Stack>
        </Popover.Body>
      </Popover>}>
      {renderInput()}

    </OverlayTrigger>
    <Button variant="light" className={`position-absolute bg-light rounded-circle p-1 border ${input.required && "circle-border"}`}
      style={{left: (input.x + input.width) * scale - 6, top: input.y * scale - 4}}
      onMouseDown={resizestart} />
  </>;
}

const ImgEditInput = forwardRef(({ input, inputLabel, scale, dragstart, dragend, menuclick, setChanged }, inputRef) => {
  return (
    <img id={`i${input.inputid}`} ref={inputRef} draggable={!isSafari} alt={inputLabel + " - Click here to sign"}  title={inputLabel}
      width={input.width * scale} height={input.height * scale}
      className={`position-absolute opacity-75 border
        ${input.required && "input-borders"} bg-light editinput`}
      style={{left: input.x * scale, top: input.y * scale}}
      src="" onDragStart={dragstart} onDragEnd={dragend} onClick={menuclick} />
  );
});

const TextEditInput = forwardRef(({ input, inputLabel, scale, dragstart, dragend, menuclick, setChanged }, inputRef) => {
  return (
    <div id={`i${input.inputid}`} ref={inputRef} className="position-absolute opacity-75" draggable={!isSafari}
      style={{left: input.x * scale, top: input.y * scale, width: input.width * scale, fontSize: input.fontsize * scale}}
      onDragStart={dragstart} onDragEnd={dragend} onClick={menuclick}>
      <Form.Control className={`bg-light ${input.required && "input-borders"} editinput`}
        placeholder={inputLabel} style={{fontSize: input.fontsize * scale }}
        value="" onChange={() => {}} />
    </div>
  );
});

const ParagraphEditInput = forwardRef(({ input, inputLabel, scale, dragstart, dragend, menuclick, setChanged }, inputRef) => {
  function resize(event) {
    input.height = event.target.clientHeight / scale;
    setChanged(true);
  }

  return (
    <div id={`i${input.inputid}`} ref={inputRef} className="position-absolute opacity-75" draggable={!isSafari}
      style={{left: input.x * scale, top: input.y * scale, width: input.width * scale, fontSize: input.fontsize * scale}}
      onDragStart={dragstart} onDragEnd={dragend} onClick={menuclick} onMouseUp={resize}>
      <Form.Control as="textarea" className={`bg-light ${input.required && "input-borders"} editinput input-form`}
        placeholder={inputLabel} style={{fontSize: input.fontsize * scale, height: input.height * scale}}
        value="" onChange={() => {}} />
    </div>
  );
});

const CheckboxEditInput = forwardRef(({ input, inputLabel, scale, dragstart, dragend, menuclick, setChanged }, inputRef) => {
  const [inputlen, setInputlen] = useState(10);

  useEffect(() => setInputlen(Math.round(input.width / (input.fontsize * 0.6)) - 3), [input.width]);

  return (
    <div id={`i${input.inputid}`} ref={inputRef} className="position-absolute opacity-75" draggable={!isSafari}
      style={{left: input.x * scale, top: input.y * scale, width: input.width * scale, fontSize: input.fontsize * scale}}
      onDragStart={dragstart} onDragEnd={dragend} onClick={menuclick}>
      <Form.Check type="checkbox" className={`editinput ${input.required && "input-borders"}`} draggable={!isSafari}
        style={{fontSize: input.fontsize * scale}} disabled={true}
        label={inputLabel.length > inputlen ? `${inputLabel.substring(0, inputlen)}...` : inputLabel} />
    </div>
  );
});
