import axios from "axios";
import { useState } from "react";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import logo from "../files/images/logo_tagline.png";


export default function Forgot() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [err, setErr] = useState({});

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      let res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot`, {
        email: email
      });
      navigate({
        pathname: "../verify",
        search: createSearchParams({
          email: res.data.email
        }).toString()
      });
    } catch (err) {
      console.error(err.response?.data);
      setErr(err.response?.data);
    }
  }

  return (
    <div className="container">
      <div className="col-lg-5 col-md-7 col-10 mx-auto">
        <div className="row">
          <img src={logo} className="logo-image my-5"></img>
        </div>
        <div className="row">
          <div className="row text-white bg-black">
            <h1 className="card-header-text my-3">FORGOT PASSWORD</h1>
          </div>
          <div className="row bg-white">
            <Form id="ForgotForm" onSubmit={handleSubmit}>
              <Form.Control className="pt-3 pb-3 mt-4 mb-3 textbox-square-border" type="email" name="email" required placeholder="Email"
                value={email} onChange={event => setEmail(event.target.value)} />
              <p>A password reset link will be sent to your email.</p>
              {err?.message && <Alert variant="danger">{err?.message}</Alert>}
              <div className="row pb-3">
                <div>
                  <Button className="float-right button-general-styling my-1 btn-auth" variant="secondary" as={Link} to={-1}>CANCEL</Button>
                  <Button className="float-right button-general-styling my-1 btn-auth" variant="primary" type="submit" form="ForgotForm">CONTINUE</Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="row slogan-text">
          <p>EVOLVE</p>
        </div>
      </div>
    </div>
  );
}
