import { useState } from "react";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

export default function CollapseList({ title, children }) {
  const [show, setShow] = useState(true);

  return <>
    <ListGroup.Item className="py-1 px-3 bg-light container">
      <div className="row py-2">
      <div className="mx-1 sub-heading col-5">
        {title}
      </div>
      <div className="col-6">
        <Button size="sm" variant="" className="float-right" onClick={() => setShow(!show)}>
          <FontAwesomeIcon  color="#6d8490" icon={show ? faAngleUp : faAngleDown} />
        </Button>
      </div>
      </div>
    </ListGroup.Item>
    {show && children}
  </>;
}
